import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Avatar from '@material-ui/core/Avatar';
import StarRate from '@material-ui/icons/Star';
import {history} from '../../helpers';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import Pagination from "material-ui-flat-pagination";
import TablePagination from '@material-ui/core/TablePagination';
import PlusOne from '@material-ui/icons/PlusOne';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { API_URL } from "../../config.js";

const styles = theme => ({
  picture: {
    marginRight: '20px'
  },
  star: {
    color: '#fec107'
  },
  toright: {
    float: 'right'
  },
  icons: {
    marginBottom: '10px',
  },
  button: {
    marginTop:'10px'
  },
  ratingcomment: {
    marginTop: '20px',
  },
  plus: {
    display: 'flex',
    verticalAlign: 'top'
  },
  minus: {
    display: 'flex',
    verticalAlign: 'top'
  },
  lenght: {
    color: '#c4c4c4',
    fontsize: '12px'
  },
  addRating: {
    padding: '10px',
    border: '1px solid #000',
    marginTop: '15px',
    width: '50px',
    height: 'calc(50px)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  paperTask: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: theme.spacing.unit * 60,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 4,
      outline: 'none',
  },
})

class ParkingComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 25,
      star: '',
      best: '',
      less: '',
      comment: ''
    };
    this.displayStar = this.displayStar.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.onSaveRating = this.onSaveRating.bind(this);
  }

  componentWillMount() {
    var data = new FormData();
    data.append("parkingid", this.props.id);

    this.props.dispatch(fetchDatasWithName(API_URL + 'ratings/ratingparkingget.php', 'ratings', 'POST', data));
  }

  displayStar(note) {
    const {classes} = this.props;
      if (note == "1") {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate/>
        <StarRate/>
        <StarRate/>
        <StarRate/>
      </div>)
      } else if (note == "2") {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate/>
        <StarRate/>
        <StarRate/>
      </div>)
      } else if (note == "3") {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate/>
        <StarRate/>
      </div>)
      } else if (note == "4") {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate/>
      </div>)
      } else {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
      </div>)
      }
  }

  gotoUser(UserID) {
    history.push('/info-user/' + UserID);
  }
  handleClick(offset) {
    this.setState({ offset });
  }

  handleChange = (value, key) => {
    this.setState({[key]: value });
  };

  handleChangePage = (event, page) => {
      this.setState({page});
  };

  handleChangeRowsPerPage = event => {
      this.setState({rowsPerPage: event.target.value});
  };

  handleCloseModal() {
    this.setState({open: false});
  }

  handleOpenModal() {
    this.setState({open: true});
  }

  renderAddParking() {
    const {classes} = this.props;
    return (
      <div className={classes.addRating} onClick={this.handleOpenModal}>
        <PlusOne/>
      </div>
    )
  }

  onSaveRating() {
    var data = new FormData();
    data.append("parkingid", this.props.id);
    data.append("ratingstar", this.state.star);
    data.append("ratingbest", this.state.best);
    data.append("ratingless", this.state.less);
    data.append("ratingcomment", this.state.comment);
    return fetch(API_URL + '/ratings/ratingadmincreate.php', {
      method: 'POST',
      headers: {'X-ACCESS-TOKEN': JSON.parse(localStorage.getItem('user')).AccessToken},
      body: data
    }).then(response => {
      return response.json();
    }).then(json => {
      this.handleCloseModal();
      window.location.reload();
      return json
    })
  }

  renderModal() {
    const {classes} = this.props;
    return (
      <div>
        <TextField
        id="outlined-title"
        label="Les plus"
        value={this.state.best}
        onChange={(e) => {this.handleChange(e.target.value, 'best')}}
        margin="normal"
        className={classes.textField}
        variant="outlined"
        fullWidth/>

        <TextField
        id="outlined-description"
        label="Les moins"
        value={this.state.less}
        onChange={(e) => {this.handleChange(e.target.value, 'less')}}
        margin="normal"
        className={classes.textField}
        variant="outlined"
        fullWidth/>

        <TextField
        id="outlined-date"
        label="Note (1 - 5)"
        value={this.state.star}
        onChange={(e) => {this.handleChange(e.target.value, 'star')}}
        margin="normal"
        className={classes.textField}
        variant="outlined"
        fullWidth/>

        <TextField
        id="outlined-status"
        label="Commentaire"
        value={this.state.comment}
        onChange={(e) => {this.handleChange(e.target.value, 'comment')}}
        margin="normal"
        variant="outlined"
        fullWidth
        className={classes.textField}/>

        <Button onClick={this.handleCloseModal} color="primary">Annuler</Button>
        <Button onClick={this.onSaveRating} color="primary">Enregistrer</Button>
      </div>
    )
  }

  render() {
    const {classes, loading, error} = this.props;

    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}>
          <div id="modal" className={classes.paperTask}>
              <Typography variant="subtitle1" id="simple-modal-description">
                  {this.state.open && this.renderModal()}
              </Typography>
          </div>
        </Modal>
        {this.renderAddParking()}
        {this.props.ratings ?
          <Card>
            <CardContent>
              <h3>Synthése des avis reçu</h3>
              <Typography><StarRate className={classes.star}/>{this.props.ratings && this.props.ratings.moyenne} / 5 - <span className={classes.lenght}>{this.props.ratings && this.props.ratings.ratings && this.props.ratings.ratings.length} avis</span></Typography>
            </CardContent>
          {this.props.ratings && this.props.ratings.ratings && this.props.ratings.ratings.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((n, i) => {
            return (
              <CardContent key={i}>
              <Divider className={classes.icons} color="primary"/>
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Typography >
                    {n.UserPicture ? <img className={classes.picture} alt="user picture" src={API_URL + "/profilepictures/" + n.UserPicture} width="50" height="50"/> : <Avatar className={classes.picture} width="50" height="50"/>}
                    {n.FirstName} {n.LasterName}</Typography>
                    <Button className={classes.button} variant="contained" color="primary" onClick={() => {this.gotoUser(n.UserID)}}>Voir l'utilisateur</Button>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <div className={classes.toright}>{this.displayStar(n.RatingStar)}</div>
                    <div className={classes.plus}>
                      <Add className={classes.icons}/>
                      <Typography>{n.RatingBest}</Typography>
                    </div>
                    <div className={classes.minus}>
                    <Remove className={classes.icons}/>
                    <Typography>{n.RatingLess}</Typography>
                    </div>
                    <Typography className={classes.ratingcomment}>{n.RatingComment}</Typography>
                  </Grid>
                </Grid>

              </CardContent>
            )
          })}
          <TablePagination
              component="div"
              count={parseInt(this.props.ratings.ratings.length)}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{
                  'aria-label': 'Previous Page',
                  'color': 'inherit',
              }}
              nextIconButtonProps={{
                  'aria-label': 'Next Page',
                  'color': 'inherit',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          </Card>
        :
          <div/>}
      </div>
    )
  }
}



function mapStateToProps(state) {
  return ({
    ratings: state.datasReducer.ratings,
    loading: state.datasReducer.loading,
    error: state.datasReducer.error,
  })
}

export default withStyles(styles)(connect(mapStateToProps)(ParkingComment));
