import React, {Component} from 'react';
import {fetchDatas} from "../../actions/fetch.actions";
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import {postDatas} from "../../actions/postData.actions";
import Grid from '@material-ui/core/Grid';
import Layout from '../common/Layout';
import Card from '@material-ui/core/Card';
import SearchBoxPagePro from '../common/SearchBox'
import { TextField } from '@material-ui/core/es';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import styles from './Styles/ChangeMailCss';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import {history} from '../../helpers';
import { API_URL } from "../../config.js";

//Champs à éditer :
    // CompanyName
    // PrenomRepresentantLegal
    // NomRepresentantLegal
    // NumeroVoieAdresse
    // TypeVoieAdresse
    // LibelleVoieAdresse
    // ComplementAdresse
    // Ville
    // Code_postal
    // ContentMessage

class ChangeMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CompanyName: '',
            PrenomRepresentantLegal: '',
            NomRepresentantLegal: '',
            NumeroVoieAdresse: '',
            TypeVoieAdresse: '',
            LibelleVoieAdresse: '',
            ComplementAdresse: '',
            Ville: '',
            Code_postal: '',
            ContentMessage: '',
        }
    }

    componentWillMount() {
        const data = new FormData();
        data.append("CompanyINSEEID", this.props.match.params.id);
        this.props.dispatch(fetchDatasWithName(API_URL + '/company/getCompanyINSEEById.php', 'INSEEProData', 'POST', data)).then( result => {
            console.log(result)
            this.setState({CompanyName: result[0].CompanyName});
            this.setState({PrenomRepresentantLegal: result[0].PrenomRepresentantLegal});
            this.setState({NomRepresentantLegal: result[0].NomRepresentantLegal});
            this.setState({NumeroVoieAdresse: result[0].NumeroVoieAdresse});
            this.setState({TypeVoieAdresse: result[0].TypeVoieAdresse});
            this.setState({LibelleVoieAdresse: result[0].LibelleVoieAdresse});
            this.setState({ComplementAdresse: result[0].ComplementAdresse});
            this.setState({Ville: result[0].Ville});
            this.setState({Code_postal: result[0].Code_postal});
            this.setState({ContentMessage: result[0].ContentMessage});
        });
    }

    handleChange(value, key) {
        this.setState({[key]: value})
    }

    validate() {
        console.log("validate");
        console.log(this.state);
        console.log(this.props.match.params.id);
        var data = new FormData();
        data.append("companyINSEEID", this.props.match.params.id);
        data.append("companyName", this.state.CompanyName);
        data.append("prenomRepresentantLegal", this.state.PrenomRepresentantLegal);
        data.append("nomRepresentantLegal", this.state.NomRepresentantLegal);
        data.append("numeroVoieAdresse", this.state.NumeroVoieAdresse);
        data.append("typeVoieAdresse", this.state.TypeVoieAdresse);
        data.append("libelleVoieAdresse", this.state.LibelleVoieAdresse);
        data.append("complementAdresse", this.state.ComplementAdresse);
        data.append("ville", this.state.Ville);
        data.append("code_postal", this.state.Code_postal);
        data.append("contentMessage", this.state.ContentMessage);
        console.log(JSON.parse(localStorage.getItem('user')).AccessToken);
        this.props.dispatch(postDatas(API_URL + '/company/companyINSEEUpdate.php', data)).then( result => {
                console.log(result);
        });
        if(window.confirm('Confimer-vous la modification de cette entreprise ? (Une fois faite, le contenu du courrier devra obligatoirement être modifié ici)')){
            window.location.reload()
        }


    }

    //Retour à la page précédente
    cancel() {
        console.log("cancelled");
        window.history.back();
    }

    download() {
        console.log("télécharger");
        var data = new FormData();
        data.append("CompanyINSEEID", this.props.match.params.id);
        this.props.dispatch(postDatas(API_URL + '/company/companyPdfById.php', data)).then( result => {
            console.log(result);
            if(window.confirm('Télécharger un PDF du courrier de cette société ?')){
                history.push(window.open(API_URL + '/company/getCompanyINSEEPdfById.php?token=' + JSON.parse(localStorage.getItem('user')).AccessToken + '&id=' + this.props.match.params.id));
            }

        });
    }

    //appel de l'API pour supprimer une entreprise
    delete() {
        let user = JSON.parse(localStorage.getItem('user'));
        const data = new FormData;
        data.append("CompanyINSEEID", this.props.match.params.id);

        if(window.confirm('Voulez-vous définitivement supprimer cette entreprise ?')) {

            return fetch(API_URL + '/company/companyINSEEDelete.php', {
                method: 'POST',
                headers: {'X-ACCESS-TOKEN': user.AccessToken},
                body: data
            }).then(response => {
                return response.json();
            })
                .then(json => {
                    history.push("/searchINSEE");
                })
        }
    }

    render() {
        const {classes} = this.props;
        // console.log(this.state);
        return (
            <div>
                <Layout>
                    <Grid container spacing={24}>
                        <div className={classes.root}>
                            <Card className={classes.cardName}>
                                <Typography className={classes.titles}>Nom de l'entreprise</Typography>
                                <TextField
                                id="CompanyName"
                                label="Nom de l'entreprise"
                                value={this.state.CompanyName}
                                onChange={(e) => {this.handleChange(e.target.value, 'CompanyName')}}
                                className={classes.textField}
                                variant="outlined"
                                />
                                <TextField
                                id="PrenomRepresentantLegal"
                                label="Prénom du représentant légal"
                                value={this.state.PrenomRepresentantLegal}
                                onChange={(e) => {this.handleChange(e.target.value, 'PrenomRepresentantLegal')}}
                                className={classes.textField}
                                variant="outlined"
                                />
                                <TextField
                                id="NomRepresentantLegal"
                                label="Nom du représentant légal"
                                value={this.state.NomRepresentantLegal}
                                onChange={(e) => {this.handleChange(e.target.value, 'NomRepresentantLegal')}}
                                className={classes.textField}
                                variant="outlined"
                                />
                            </Card>
                            <Card className={classes.cardAddress}>
                                <Typography className={classes.titles}>Adresse</Typography>
                                <TextField
                                id="NumeroVoieAdresse"
                                label="Numéro de rue"
                                value={this.state.NumeroVoieAdresse}
                                onChange={(e) => {this.handleChange(e.target.value, 'NumeroVoieAdresse')}}
                                className={classes.textField}
                                variant="outlined"
                                />
                                <TextField
                                id="TypeVoieAdresse"
                                label="Type de voie"
                                value={this.state.TypeVoieAdresse}
                                onChange={(e) => {this.handleChange(e.target.value, 'TypeVoieAdresse')}}
                                className={classes.textField}
                                variant="outlined"
                                />
                                <TextField
                                id="LibelleVoieAdresse"
                                label="Libellé voie"
                                value={this.state.LibelleVoieAdresse}
                                onChange={(e) => {this.handleChange(e.target.value, 'LibelleVoieAdresse')}}
                                className={classes.textField}
                                variant="outlined"
                                />
                                <TextField
                                id="ComplementAdresse"
                                label="Complément d'adresse"
                                value={this.state.ComplementAdresse}
                                multiline
                                onChange={(e) => {this.handleChange(e.target.value, 'ComplementAdresse')}}
                                className={classes.textField}
                                variant="outlined"
                                />
                                <TextField
                                id="Ville"
                                label="Ville"
                                value={this.state.Ville}
                                onChange={(e) => {this.handleChange(e.target.value, 'Ville')}}
                                className={classes.textField}
                                variant="outlined"
                                />
                                <TextField
                                id="Code_postal"
                                label="Code Postal"
                                value={this.state.Code_postal}
                                onChange={(e) => {this.handleChange(e.target.value, 'Code_postal')}}
                                className={classes.textField}
                                variant="outlined"
                                />
                            </Card>
                            <Card className={classes.cardMessage}>
                                <Typography className={classes.titles}>Contenu du message</Typography>
                                <TextField
                                id="ContentMessage"
                                label="Contenu de la lettre"
                                multiline
                                rows='10'
                                value={this.state.ContentMessage}
                                onChange={(e) => {this.handleChange(e.target.value, 'ContentMessage')}}
                                className={classes.textFieldContent}
                                variant="outlined"
                                />
                                <div className={classes.buttons}>
                                    <Button color='secondary' variant="contained" onClick={() => this.cancel()} className={classes.buttonValidate}>Retour</Button>
                                    <Button color='secondary' variant="contained" onClick={() => this.validate()} className={classes.buttonValidate}>Valider</Button>
                                    <Button color='secondary' variant="contained" onClick={() => this.delete()} className={classes.buttonValidate}>Supprimer</Button>
                                    <Button color='secondary' variant="contained" onClick={() => this.download()} className={classes.buttonValidate}>Télécharger</Button>
                                </div>
                            </Card>
                        </div>
                    </Grid>
                </Layout>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return ({
      INSEEproData: state.datasReducer.INSEEproData,
    })
  }

export default withStyles(styles)(connect(mapStateToProps)(ChangeMail));
