import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { connect } from 'react-redux'
import { fetchDatas } from '../../actions/fetch.actions'
import ProSelectMap from './ProSelectMap'
import { fetchDatasWithName } from '../../actions/fetchWithName.actions'
import { API_URL } from '../../config.js'


const styles = theme => ({
    root: {
        overflow: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
    },
    wrapper: {
        minWidth: "100%",
        marginTop: 24,
    },
    paper: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit * 2,
    },
    searchbar: {
        margin: theme.spacing.unit,
        padding: 5
    },
    selectBox: {
        width: 90,
    },
    checkbox: {
        marginLeft: 50,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    submit:{
      margin: '10px',
      minWidth: 120
    }
});

class SearchBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radius: 0,
            activity: '',
            location: '',
            proDatas: '',
            data: '',
            name: '',
      };

        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(fetchDatas(API_URL + "/listcompanytype.php", 'types', 'POST'));

        const data = new FormData();
        data.append('inqueryid', this.props.id);
        this.props.dispatch(fetchDatasWithName(API_URL + "/company/companygetallforinquery.php", 'proDatas', 'POST', data));
    }

    componentWillReceiveProps = props => {
        if (props.proDatas !== this.props.proDatas) {
            this.setState({proDatas: props.proDatas});
        }
    };

    onChange(value, key) {
        this.setState({[key]: value});
    };

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }


    handleSubmit() {
        const {location, activity, name} = this.state;

        const data = new FormData();
        data.append("location", location);
        data.append("activity", activity);
        data.append("inqueryid", this.props.id);
        data.append("name", name);
        this.props.dispatch(fetchDatasWithName(API_URL + "/company/companygetallforinquery.php", 'proDatas', 'POST', data));
    }

    resetState() {
        this.setState({
            radius: 0,
            location: '',
            activity: '',
            name: '',
        });

        const data = new FormData();
        data.append("inqueryid", this.props.id);
        this.props.dispatch(fetchDatasWithName(API_URL + "/company/companygetallforinquery.php", 'proDatas', 'POST', data));
    };

    render() {
        const {classes, loading, error} = this.props;
        const radius = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200];
        const {activity, location, name} = this.state;

        if (error) {
            return (<div>Error! {error.message} </div>);
        }

        if (loading) {
            return <div>Loading ...</div>
        }

        return (
            <div className={classes.wrapper}>
                <Paper>
                    <div className={classes.root}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <div>
                                    <TextField
                                        id="standard-textarea"
                                        type="search"
                                        placeholder='Ville'
                                        value={location}
                                        onChange={(e) => {
                                            this.onChange(e.target.value, 'location')
                                        }}
                                        className={classes.searchbar}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}/>
                                    <Grid/>
                                    <Grid item xs={12}/>
                                    <form className={classes.root} autoComplete="off">
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="activité">Activité</InputLabel>
                                            <Select
                                                value={activity}
                                                onChange={(e) => {
                                                    this.onChange(e.target.value, 'activity')
                                                }}
                                                inputProps={{
                                                    name: 'activity',
                                                    id: 'activity-id',
                                                }}>
                                                <MenuItem value=''>
                                                    <p>Aucun</p>
                                                </MenuItem>
                                                {this.props.types && this.props.types.map((data, i) => (
                                                    <MenuItem key={i} value={data.CompanyType}>
                                                        {data.TypeName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="périmètre">Perimètre</InputLabel>
                                            <Select
                                                value={this.state.radius}
                                                onChange={(e) => {
                                                    this.onChange(e.target.value, 'radius')
                                                }}
                                                inputProps={{
                                                    name: 'radius',
                                                    id: 'radius-id',
                                                }}>
                                                <MenuItem value=''>
                                                    <p>Aucun</p>
                                                </MenuItem>
                                                {radius.map((radius, i) => (
                                                    <MenuItem key={i} value={radius}>
                                                        {(radius)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <TextField
                                        id="standard-textarea"
                                        type="search"
                                        placeholder='Entreprise'
                                        value={name}
                                        onChange={(e) => {
                                            this.onChange(e.target.value, 'name')
                                        }}
                                        className={classes.searchbar}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}/>

                                        <Button className={classes.submit} variant='contained' color='primary' onClick={() => {this.handleSubmit()}}>Filtrer</Button>
                                        <Button className={classes.submit} variant='contained' color='primary' onClick={() => {this.resetState()}}>Reset</Button>
                                    </form>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <ProSelectMap id={this.props.id}
                                              radius={this.state.radius}
                                              activity={this.state.activity}
                                              name={this.state.name}/>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </div>
        );
    }
}

SearchBox.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    proDatas: state.datasReducer.proDatas,
    oneQuerie: state.datasReducer.oneQuerie,
    loading: state.datasReducer.loading,
    error: state.datasReducer.error,
    types: state.datasReducer.types,
    data: state.postDatasReducer.datas,
});

export default connect(mapStateToProps)(withStyles(styles)(SearchBox));
