import React from 'react';
import {connect} from 'react-redux';
import {fetchDatas} from "../../actions/fetch.actions";
import {CSVLink} from "react-csv";
import { API_URL } from '../../config.js'

class UserDownload extends React.Component {

    componentWillMount() {
        this.props.dispatch(fetchDatas(API_URL + "/usergetall.php", 'proDatas', 'POST'));

    }

    render() {
        const data = this.props.proDatas;
        return (
            <CSVLink data={data}>Download me</CSVLink>
        )
    };
}

const mapStateToProps = (state) => ({
    proDatas: state.datasReducer.proDatas,
    loading: state.datasReducer.loading,
    error: state.datasReducer.error
});

export default (connect(mapStateToProps)(UserDownload))
