import React, {Component} from 'react';
import SearchBox from './SearchBox';
import Layout from '../common/Layout';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Assignment from '@material-ui/icons/Assignment';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import ProfileInquery from './ProfileInquery';
import ProfileUser from './ProfileUser';
import ProContacted from './ProContacted';
import {connect} from "react-redux";
import {fetchDatas} from "../../actions/fetch.actions";
import { API_URL } from '../../config.js'

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    title: {
        display: 'flex',
        textAlign: 'center'
    },
    title1: {
        display: 'flex',
        marginTop: 20,
    },
    icon: {
        marginRight: 25,
        fontSize: 40,
    },
    cardPro: {
        maxWidth: 1000,
        flexWrap: 'nowrap',
    },
    cardUser: {
        maxWidth: 'max-content',
        flexWrap: 'nowrap',
        width: '-moz-max-content'
    },
    carcontentpro: {
        maxHeight: '100%',
        display: 'flex',
        overflow: 'auto',
        width: '100%',
        flexWrap: 'nowrap',
    },
    divPro: {
        overflowX: 'auto',
        display: 'flex',
        width: '100%',
        flexWrap: 'nowrap',
    },
    header: {
        display: 'inline',
        textTransform: 'uppercase'
    }
});

class PageQueries extends Component {

    componentWillMount() {
        if (!this.props.adminInfo) {
            this.props.dispatch(fetchDatas(API_URL + "/admingetall.php", 'adminInfo', 'POST'));
        }
    }

    render() {
        const {classes, loading, error} = this.props;
        if (error) {
            return (<div>error in main page! {error.message} </div>);
        }

        if (loading) {
            return <div>Loading ...</div>
        }

        return (
            <div className={classes.root}>
                <Layout>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <div className={classes.title}>
                                <Assignment color="primary" className={classes.icon}/>
                                <Typography className={classes.header} color="inherit" noWrap>Informations</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={3} md={3} className={classes.cardUser}>
                            <Card className={classes.cardUser}>
                                <CardContent className={classes.cardUser}>
                                    <ProfileUser id={this.props.match.params.id}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <ProfileInquery id={this.props.match.params.id}/>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <div className={classes.title}>
                                <Assignment color="primary" className={classes.icon}/>
                                <Typography className={classes.header} color="inherit" noWrap>Professionnels Contactés</Typography>
                            </div>
                            <div>
                                <Card className={classes.cardPro}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <ProContacted id={this.props.match.params.id}/>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SearchBox id={this.props.match.params.id}/>
                        </Grid>
                    </Grid>
                </Layout>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return ({
        adminInfo: state.datasReducer.adminInfo,
    })
}

export default withStyles(styles)(connect(mapStateToProps)(PageQueries));
