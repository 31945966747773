import React, {Component} from 'react';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {postDatas} from "../../actions/postData.actions";
import Layout from '../common/Layout';
import {history} from '../../helpers';
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { API_URL } from "../../config.js";

const styles = () => ({
  cardStyle: {
    display: 'inline-block',
    width: '30vw',
    verticalAlign: 'top',
    transitionDuration: '0.3s',
  },
  columnStyle: {
    display: 'inline-block',
    verticalAlign: 'top',
    transitionDuration: '0.3s',
  },
  service: {
    display: 'flex',
    flexFlow: 'row',
  },
  cardStyleService2: {
    display: 'inline-block',
    verticalAlign: 'top',
    transitionDuration: '0.3s',
  },
  cardStyleService3: {
    display: 'inline-flex',
    flexDirection: 'column'
  },
  titreService: {
    fontweight: 'bold',
  },
  title: {
    fontWeight: 'bold'
  },
  submit: {
    margin: '10px',
    minWidth: 200
  }
});


class AddProPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      classes: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  onChange(value, key) {
    this.setState({[key]: value});
  }

  handleSubmit() {
    const {
      CompanyName, Adresse, Ville, Code_postal, Email, Phone1, Phone2, Fax, Description,
      Website, Facebook, Twitter, Instagram, Youtube, Siren, Siret, VatID,
      PrenomRepresentantLegal, NomRepresentantLegal, AdresseRepresentantLegal, VilleRepresentantLegal, PaysRepresentantLegal, BirthdayRepresentantLegal,
      Service_Seller, Service_SellerTrailer, Service_Rent, Service_Chandlery, Service_Repair, Service_RepairEngine,Service_NavalArchitect,
      Service_SellEngineSpareParts, Service_Maker, Service_SailManufacturerAndRepair, Service_RepairElectronics, Service_Electrics, Service_RepairEngine_HorsBord,
      Service_SellerEngine,Service_BoatBuilding,Service_BoatTransportation,Service_MarineCarpentry,Service_StainlessSteelSpecialist, Service_RepairEngine_InBord,
      Service_SellerBoatLeather, Service_DryMarina, Service_HarbourSpots, Service_Cleaner, Service_BoatTowing, Service_BoatRecycling,
      Service_SellerFishingEquipment, Service_BoatLifting, Service_BoatGarage, Service_CareTaker, Latitude, Longitude, Service_Insurance, Service_Expertise,
      Service_UnderwaterWorksAndDivers, Service_HeatingSpecialist, Service_Photographer, Service_Videomaker, Service_BoatLicense, Service_SaillingSchool,
      Service_GateringOnBoard, Service_EventPlanning, Service_Stratification, Service_Carpentry, Service_Mature_Ajustment, Service_New_Boat, Service_Boat_Transport,
      Service_SailingCleaning, Service_PaintingHull, Service_ManufactingRigging, Service_FireProtection, Service_InflatableReparation,
    } = this.state;

    const data = new FormData();
    data.append("CompanyName", CompanyName);
    data.append("Adresse", Adresse);
    data.append("Ville", Ville);
    data.append('Latitude', Latitude);
    data.append('Longitude', Longitude);
    data.append("Code_postal", Code_postal);
    data.append("Email", Email);
    data.append("Phone1", Phone1);
    data.append("Phone2", Phone2);
    data.append("Fax", Fax);
    data.append("Description", Description);
    data.append("Website", Website);
    data.append("Facebook", Facebook);
    data.append("Twitter", Twitter);
    data.append("Instagram", Instagram);
    data.append("Youtube", Youtube);
    data.append("Siren", Siren);
    data.append("Siret", Siret);
    data.append("VatID", VatID);
    data.append("PrenomRepresentantLegal", PrenomRepresentantLegal);
    data.append("NomRepresentantLegal", NomRepresentantLegal);
    data.append("AdresseRepresentantLegal", AdresseRepresentantLegal);
    data.append("VilleRepresentantLegal", VilleRepresentantLegal);
    data.append("PaysRepresentantLegal", PaysRepresentantLegal);
    data.append("BirthdayRepresentantLegal", BirthdayRepresentantLegal);
    //Service
    data.append("Service_Seller", Service_Seller);
    data.append("Service_SellerEngine", Service_SellerEngine);
    data.append("Service_BoatBuilding", Service_BoatBuilding);
    data.append("Service_BoatTransportation", Service_BoatTransportation);
    data.append("Service_MarineCarpentry", Service_MarineCarpentry);
    data.append("Service_StainlessSteelSpecialist", Service_StainlessSteelSpecialist);
    data.append("Service_NavalArchitect", Service_NavalArchitect);
    data.append("Service_SellerTrailer", Service_SellerTrailer);
    data.append("Service_Rent", Service_Rent);
    data.append("Service_Chandlery", Service_Chandlery);
    data.append("Service_Repair", Service_Repair);
    data.append("Service_RepairEngine", Service_RepairEngine);
    data.append("Service_SellEngineSpareParts", Service_SellEngineSpareParts);
    data.append("Service_Maker", Service_Maker);
    data.append("Service_SailManufacturerAndRepair", Service_SailManufacturerAndRepair);
    data.append("Service_RepairElectronics", Service_RepairElectronics);
    data.append("Service_Electrics", Service_Electrics);
    data.append("Service_SellerBoatLeather", Service_SellerBoatLeather);
    data.append("Service_DryMarina", Service_DryMarina);
    data.append("Service_HarbourSpots", Service_HarbourSpots);
    data.append("Service_Cleaner", Service_Cleaner);
    data.append("Service_BoatTowing", Service_BoatTowing);
    data.append("Service_BoatRecycling", Service_BoatRecycling);
    data.append("Service_SellerFishingEquipment", Service_SellerFishingEquipment);
    data.append("Service_BoatLifting", Service_BoatLifting);
    data.append("Service_BoatGarage", Service_BoatGarage);
    data.append("Service_CareTaker", Service_CareTaker);
    data.append("Service_Insurance", Service_Insurance);
    data.append("Service_Expertise", Service_Expertise);
    data.append("Service_UnderwaterWorksAndDivers", Service_UnderwaterWorksAndDivers);
    data.append("Service_HeatingSpecialist", Service_HeatingSpecialist);
    data.append("Service_Photographer", Service_Photographer);
    data.append("Service_Videomaker", Service_Videomaker);
    data.append("Service_BoatLicense", Service_BoatLicense);
    data.append("Service_SaillingSchool", Service_SaillingSchool);
    data.append("Service_GateringOnBoard", Service_GateringOnBoard);
    data.append("Service_EventPlanning", Service_EventPlanning);
    data.append("Service_Stratification", Service_Stratification);
    data.append("Service_Carpentry", Service_Carpentry);
    data.append("Service_Mature_Ajustment", Service_Mature_Ajustment);
    data.append("Service_New_Boat", Service_New_Boat);
    data.append("Service_Boat_Transport", Service_Boat_Transport);
    data.append("Service_SailingCleaning", Service_SailingCleaning);
    data.append("Service_PaintingHull", Service_PaintingHull);
    data.append("Service_ManufactingRigging", Service_ManufactingRigging);
    data.append("Service_RepairEngine_HorsBord", Service_RepairEngine_HorsBord);
    data.append("Service_RepairEngine_InBord", Service_RepairEngine_InBord);
    data.append("Service_FireProtection", Service_FireProtection);
    data.append("Service_InflatableReparation", Service_InflatableReparation);

    //add update company not create php
    this.props.dispatch(postDatas(API_URL + '/addcompanynotcreate.php', data));
    history.push("/search");
  }

  render() {
    const {classes, loading, error} = this.props;

    const {
      CompanyName, Adresse, Ville, Code_postal, Email, Phone1, Phone2, Fax, Description,
      Website, Facebook, Twitter, Instagram, Youtube, Siren, Siret, VatID,
      PrenomRepresentantLegal, NomRepresentantLegal, AdresseRepresentantLegal, VilleRepresentantLegal, PaysRepresentantLegal, BirthdayRepresentantLegal
    } = this.state;

    const {
      Service_Seller = "0",Service_SellerEngine = "0", Service_SellerTrailer = "0", Service_Rent = "0", Service_Chandlery = "0", Service_Repair = "0", Service_RepairEngine_InBord = "0", Service_RepairEngine_HorsBord = "0",
      Service_SellEngineSpareParts = "0", Service_SailManufacturerAndRepair = "0", Service_RepairElectronics = "0", Service_Electrics = "0",Service_BoatBuilding = "0",Service_BoatTransportation = "0",Service_MarineCarpentry = "0",
      Service_SellerBoatLeather = "0", Service_DryMarina = "0", Service_HarbourSpots = "0", Service_Cleaner = "0", Service_BoatTowing = "0", Service_BoatRecycling = "0",Service_StainlessSteelSpecialist = "0",
      Service_SellerFishingEquipment = "0", Service_BoatLifting = "0", Service_BoatGarage = "0", Service_CareTaker = "0", Service_Insurance = "0", Service_Expertise = "0", Service_Maker = "0",Service_NavalArchitect = "0",
      Service_UnderwaterWorksAndDivers = "0", Service_HeatingSpecialist = "0", Service_Photographer = "0", Service_Videomaker = "0", Service_BoatLicense = "0", Service_SaillingSchool = "0",
      Service_GateringOnBoard = "0", Service_EventPlanning = "0", Service_Stratification = "0", Service_Carpentry = "0", Service_Mature_Ajustment = "0", Service_New_Boat = "0", Service_Boat_Transport = "0",
      Service_SailingCleaning = "0", Service_PaintingHull = "0", Service_ManufactingRigging = "0", Service_FireProtection = "0", Service_InflatableReparation = "0",
    } = this.state;

    if (error) {
      return (<div>Error! {error.message} </div>);
    }

    if (loading) {
      return <div>Loading ...</div>
    }

    return (
        <div>
          <Layout>
            <form onSubmit={this.handleSubmit}>
              <Card>
                <CardContent className={classes.cardStyle}>
                  <h3 className={classes.title}>Info de l'entreprise</h3>

                  <TextField
                      id="outlined-name"
                      label="Nom de l'entreprise"
                      required
                      value={CompanyName}
                      onChange={(e) => {this.onChange(e.target.value, 'CompanyName')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-adresse"
                      label="Adresse"
                      value={Adresse}
                      onChange={(e) => {this.onChange(e.target.value, 'Adresse')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-ville"
                      label="Ville"
                      value={Ville}
                      onChange={(e) => {this.onChange(e.target.value, 'Ville')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-code-postal"
                      label="Code postal"
                      value={Code_postal}
                      onChange={(e) => {this.onChange(e.target.value, 'Code_postal')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-telephone"
                      label="Téléphone"
                      value={Phone1}
                      onChange={(e) => {this.onChange(e.target.value, 'Phone1')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-mobile"
                      label="Mobile"
                      value={Phone2}
                      onChange={(e) => {this.onChange(e.target.value, 'Phone2')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-email"
                      label="Email"
                      value={Email}
                      onChange={(e) => {this.onChange(e.target.value, 'Email')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-fax"
                      label="Fax"
                      value={Fax}
                      onChange={(e) => {this.onChange(e.target.value, 'Fax')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-description"
                      label="Description"
                      placeholder="Description"
                      value={Description}
                      multiline
                      rows="5"
                      onChange={(e) => {this.onChange(e.target.value, 'Description')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>
                </CardContent>

                <CardContent className={classes.cardStyleService3}>
                  <CardContent className={classes.cardStyle}>
                    <h3 className={classes.title}>Media</h3>

                    <TextField
                        id="outlined-website"
                        label="Website"
                        value={Website}
                        onChange={(e) => {this.onChange(e.target.value, 'Website')}}
                        className={classes.inputTextField}
                        margin="normal"
                        variant="outlined"
                        fullWidth/>

                    <TextField
                        id="outlined-facebook"
                        label="Facebook"
                        value={Facebook}
                        onChange={(e) => {this.onChange(e.target.value, 'Facebook')}}
                        className={classes.inputTextField}
                        margin="normal"
                        variant="outlined"
                        fullWidth/>

                    <TextField
                        id="outlined-twitter"
                        label="Twitter"
                        value={Twitter}
                        onChange={(e) => {this.onChange(e.target.value, 'Twitter')}}
                        className={classes.inputTextField}
                        margin="normal"
                        variant="outlined"
                        fullWidth/>

                    <TextField
                        id="outlined-instagram"
                        label="Instagram"
                        value={Instagram}
                        onChange={(e) => {this.onChange(e.target.value, 'Instagram')}}
                        className={classes.inputTextField}
                        margin="normal"
                        variant="outlined"
                        fullWidth/>

                    <TextField
                        id="outlined-youtube"
                        label="Youtube"
                        value={Youtube}
                        onChange={(e) => {this.onChange(e.target.value, 'Youtube')}}
                        className={classes.inputTextField}
                        margin="normal"
                        variant="outlined"
                        fullWidth/>
                  </CardContent>

                  <CardContent className={classes.cardStyle}>
                    <h3 className={classes.title}>Informations Legale</h3>

                    <TextField
                        id="outlined-siren"
                        label="Siren"
                        value={Siren}
                        onChange={(e) => {this.onChange(e.target.value, 'Siren')}}
                        className={classes.inputTextField}
                        margin="normal"
                        variant="outlined"
                        fullWidth/>

                    <TextField
                        id="outlined-siret"
                        label="Siret"
                        value={Siret}
                        onChange={(e) => {this.onChange(e.target.value, 'Siret')}}
                        className={classes.inputTextField}
                        margin="normal"
                        variant="outlined"
                        fullWidth/>

                    <TextField
                        id="outlined-vatif"
                        label="Numéro de TVA"
                        value={VatID}
                        onChange={(e) => {this.onChange(e.target.value, 'VatID')}}
                        className={classes.inputTextField}
                        margin="normal"
                        variant="outlined"
                        fullWidth/>
                  </CardContent>
                </CardContent>
                <CardContent className={classes.cardStyle}>
                  <h3 className={classes.title}> Informations du represant legal</h3>
                  <TextField
                      id="outlined-prenomrepresentant"
                      label="Prénom du repésentant légal"
                      value={PrenomRepresentantLegal}
                      onChange={(e) => {this.onChange(e.target.value, 'PrenomRepresentantLegal')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-nomrepresentant"
                      label="Nom du representant légal"
                      value={NomRepresentantLegal}
                      onChange={(e) => {this.onChange(e.target.value, 'NomRepresentantLegal')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-adresserpresentant"
                      label="Adresse du représentant légal"
                      value={AdresseRepresentantLegal}
                      onChange={(e) => {this.onChange(e.target.value, 'AdresseRepresentantLegal')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-villerepresentant"
                      label="Ville du représentant légal"
                      value={VilleRepresentantLegal}
                      onChange={(e) => {this.onChange(e.target.value, 'VilleRepresentantLegal')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-paysrepresetant"
                      label="Pays du représentant légal"
                      value={PaysRepresentantLegal}
                      onChange={(e) => {this.onChange(e.target.value, 'PaysRepresentantLegal')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>

                  <TextField
                      id="outlined-birthdayrepresentatn"
                      label="Date de naissance"
                      value={BirthdayRepresentantLegal}
                      onChange={(e) => {this.onChange(e.target.value, 'BirthdayRepresentantLegal')}}
                      className={classes.inputTextField}
                      margin="normal"
                      variant="outlined"
                      fullWidth/>
                </CardContent>

                <CardContent className={classes.cardStyleService2}>
                  <h3>Services</h3>
                  <div className={classes.service}>

                    <CardContent className={classes.columnStyle}>
                      <h4 className={classes.title}>Concession et magasins</h4>
                      <Typography><Checkbox color="primary" checked={Service_Seller !== "0"} onChange={() => {this.onChange(Service_Seller === "0" ? "1" : "0", 'Service_Seller')}}/>Vente de Bateau</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_SellerEngine !== "0"} onChange={() => {this.onChange(Service_SellerEngine === "0" ? "1" : "0", 'Service_SellerEngine')}}/>Vente de Moteur</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_SellerTrailer !== "0"} onChange={() => {this.onChange(Service_SellerTrailer === "0" ? "1" : "0", 'Service_SellerTrailer')}}/>Vente de remorques</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Rent !== "0"} onChange={() => {this.onChange(Service_Rent === "0" ? "1" : "0", 'Service_Rent')}}/>Location de bateau</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Chandlery !== "0"} onChange={() => {this.onChange(Service_Chandlery === "0" ? "1" : "0", 'Service_Chandlery')}}/>Accastillage</Typography><br/>
                    </CardContent>

                    <CardContent className={classes.columnStyle}>
                      <h4 className={classes.title}>Fabrication et maintenance</h4>
                      <Typography><Checkbox color="primary" checked={Service_Repair !== "0"} onChange={() => {this.onChange(Service_Repair === "0" ? "1" : "0", 'Service_Repair')}}/>Entretien de bateau</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_RepairEngine_InBord !== "0"} onChange={() => {this.onChange(Service_RepairEngine_InBord === "0" ? "1" : "0", 'Service_RepairEngine_InBord')}}/>Entretien de moteur inboard</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_RepairEngine_HorsBord !== "0"} onChange={() => {this.onChange(Service_RepairEngine_HorsBord === "0" ? "1" : "0", 'Service_RepairEngine_HorsBord')}}/>Entretien de moteur hors-bord</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_SellEngineSpareParts !== "0"} onChange={() => {this.onChange(Service_SellEngineSpareParts === "0" ? "1" : "0", 'Service_SellEngineSpareParts')}}/>Vente pièces détachées</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_BoatBuilding !== "0"} onChange={() => {this.onChange(Service_BoatBuilding === "0" ? "1" : "0", 'Service_BoatBuilding')}}/>Chantier de construction navale</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Maker !== "0"} onChange={() => {this.onChange(Service_Maker === "0" ? "1" : "0", 'Service_Maker')}}/>Chantier Naval</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_SailManufacturerAndRepair !== "0"} onChange={() => {this.onChange(Service_SailManufacturerAndRepair === "0" ? "1" : "0", 'Service_SailManufacturerAndRepair')}}/>Voilerie</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_RepairElectronics !== "0"} onChange={() => {this.onChange(Service_RepairElectronics === "0" ? "1" : "0", 'Service_RepairElectronics')}}/>Electronique</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Electrics !== "0"} onChange={() => {this.onChange(Service_Electrics === "0" ? "1" : "0", 'Service_Electrics')}}/>Elétricité</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_SellerBoatLeather !== "0"}onChange={() => {this.onChange(Service_SellerBoatLeather === "0" ? "1" : "0", 'Service_SellerBoatLeather')}}/>Sellerie nautique</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_BoatTransportation !== "0"}onChange={() => {this.onChange(Service_BoatTransportation === "0" ? "1" : "0", 'Service_BoatTransportation')}}/>Convoyage</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_MarineCarpentry !== "0"}onChange={() => {this.onChange(Service_MarineCarpentry === "0" ? "1" : "0", 'Service_MarineCarpentry')}}/>Charpente marine</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_StainlessSteelSpecialist !== "0"}onChange={() => {this.onChange(Service_StainlessSteelSpecialist === "0" ? "1" : "0", 'Service_StainlessSteelSpecialist')}}/>Spécialiste Inox</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_NavalArchitect !== "0"}onChange={() => {this.onChange(Service_NavalArchitect === "0" ? "1" : "0", 'Service_NavalArchitect')}}/>Architecte naval</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_UnderwaterWorksAndDivers !== "0"}onChange={() => {this.onChange(Service_UnderwaterWorksAndDivers === "0" ? "1" : "0", 'Service_UnderwaterWorksAndDivers')}}/>Travaux sous-marins et plongeurs</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_HeatingSpecialist !== "0"}onChange={() => {this.onChange(Service_HeatingSpecialist === "0" ? "1" : "0", 'Service_HeatingSpecialist')}}/>Chauffagiste</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_FireProtection !== "0"}onChange={() => {this.onChange(Service_FireProtection === "0" ? "1" : "0", 'Service_FireProtection')}}/>Protection Incendie</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_InflatableReparation !== "0"}onChange={() => {this.onChange(Service_InflatableReparation === "0" ? "1" : "0", 'Service_InflatableReparation')}}/>Réparation pneumatiques</Typography><br/>
                    </CardContent>

                    <CardContent className={classes.columnStyle}>
                      <h4 className={classes.title}>Équipement Voilier</h4>
                      <Typography><Checkbox color="primary" checked={Service_SailingCleaning !== "0"} onChange={() => {this.onChange(Service_SailingCleaning === "0" ? "1" : "0", 'Service_SailingCleaning')}}/>Nettoyage voile</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_PaintingHull !== "0"} onChange={() => {this.onChange(Service_PaintingHull === "0" ? "1" : "0", 'Service_PaintingHull')}}/>Peinture et marquage sur coques ou voiles</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_ManufactingRigging !== "0"} onChange={() => {this.onChange(Service_ManufactingRigging === "0" ? "1" : "0", 'Service_ManufactingRigging')}}/>Fabrication de gréements, espars et composite</Typography><br/>
                    </CardContent>

                    <CardContent className={classes.columnStyle}>
                      <h4 className={classes.title}>Services</h4>
                      <Typography><Checkbox color="primary" checked={Service_Insurance !== "0"} onChange={() => {this.onChange(Service_Insurance === "0" ? "1" : "0", 'Service_Insurance')}}/>Assurance</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Expertise !== "0"} onChange={() => {this.onChange(Service_Expertise === "0" ? "1" : "0", 'Service_Expertise')}}/>Expertise maritime</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_DryMarina !== "0"} onChange={() => {this.onChange(Service_DryMarina === "0" ? "1" : "0", 'Service_DryMarina')}}/>Place à sec</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_HarbourSpots !== "0"} onChange={() => {this.onChange(Service_HarbourSpots === "0" ? "1" : "0", 'Service_HarbourSpots')}}/>Place à flot</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Cleaner !== "0"} onChange={() => {this.onChange(Service_Cleaner === "0" ? "1" : "0", 'Service_Cleaner')}}/>Nettoyage</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_BoatTowing !== "0"} onChange={() => {this.onChange(Service_BoatTowing === "0" ? "1" : "0", 'Service_BoatTowing')}}/>Remorquage</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_BoatRecycling !== "0"} onChange={() => {this.onChange(Service_BoatRecycling === "0" ? "1" : "0", 'Service_BoatRecycling')}}/>Déconstruction et recyclage</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_SellerFishingEquipment !== "0"} onChange={() => {this.onChange(Service_SellerFishingEquipment === "0" ? "1" : "0", 'Service_SellerFishingEquipment')}}/>Equipement de pêche</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_BoatLifting !== "0"} onChange={() => {this.onChange(Service_BoatLifting === "0" ? "1" : "0", 'Service_BoatLifting')}}/>Levage Grutage</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_BoatGarage !== "0"} onChange={() => {this.onChange(Service_BoatGarage === "0" ? "1" : "0", 'Service_BoatGarage')}}/>Gardiennage</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_CareTaker !== "0"} onChange={() => {this.onChange(Service_CareTaker === "0" ? "1" : "0", 'Service_CareTaker')}}/>Concierge</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Photographer !== "0"} onChange={() => {this.onChange(Service_Photographer === "0" ? "1" : "0", 'Service_Photographer')}}/>Photographe</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Videomaker !== "0"} onChange={() => {this.onChange(Service_Videomaker === "0" ? "1" : "0", 'Service_Videomaker')}}/>Vidéaste</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_BoatLicense !== "0"} onChange={() => {this.onChange(Service_BoatLicense === "0" ? "1" : "0", 'Service_BoatLicense')}}/>Permis Bateau</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_SaillingSchool !== "0"} onChange={() => {this.onChange(Service_SaillingSchool === "0" ? "1" : "0", 'Service_SaillingSchool')}}/>École de voile</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_GateringOnBoard !== "0"} onChange={() => {this.onChange(Service_GateringOnBoard === "0" ? "1" : "0", 'Service_GateringOnBoard')}}/>Restauration à bord</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_EventPlanning !== "0"} onChange={() => {this.onChange(Service_EventPlanning === "0" ? "1" : "0", 'Service_EventPlanning')}}/>Événementiel</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Stratification !== "0"} onChange={() => {this.onChange(Service_Stratification === "0" ? "1" : "0", 'Service_Stratification')}}/>Stratification</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Carpentry !== "0"} onChange={() => {this.onChange(Service_Carpentry === "0" ? "1" : "0", 'Service_Carpentry')}}/>Menuiserie</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Mature_Ajustment !== "0"} onChange={() => {this.onChange(Service_Mature_Ajustment === "0" ? "1" : "0", 'Service_Mature_Ajustment')}}/>Réglage mature</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_New_Boat !== "0"} onChange={() => {this.onChange(Service_New_Boat === "0" ? "1" : "0", 'Service_New_Boat')}}/>Préparation bateau neuf</Typography><br/>
                      <Typography><Checkbox color="primary" checked={Service_Boat_Transport !== "0"} onChange={() => {this.onChange(Service_Boat_Transport === "0" ? "1" : "0", 'Service_Boat_Transport')}}/>Transport de bateau</Typography><br/>
                    </CardContent>

                  </div>
                </CardContent>
                <CardContent>
                  <Button className={classes.submit}variant='contained' color='primary' onClick={() => {this.handleSubmit()}}>Envoyer</Button>
                </CardContent>
              </Card>
            </form>
          </Layout>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return ({
    loading: state.datasReducer.loading,
    error: state.datasReducer.error
  })
}

export default withStyles(styles)(connect(mapStateToProps)(AddProPage));
