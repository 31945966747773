import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {postDatas} from "../../actions/postData.actions";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import { API_URL} from "../../config.js";

const styles = theme => ({
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400,
    },
});

class FormDialogActivity extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            Open: false,
            type: '',
            details: '',
            datehappen: new Date().toISOString().split('T')[0],
        };

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClickOpen() {
        this.setState({Open: true})
    }

    onChange(value, key) {
        this.setState({[key]: value});

    };

    handleClose() {
        this.setState({Open: false})
    }

    handleSubmit() {
        const {type, details, datehappen} = this.state;
        const data = new FormData();
        data.append('type', type);
        data.append('activitydescription', details);
        data.append('datehappen', datehappen);
        data.append('allcompaniesid', this.props.CompanyID);
        data.append('inqueryid', this.props.InqueryID);

        this.props.dispatch(postDatas(API_URL + '/activity/activityadd.php', data));
        this.handleClose();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    };

    render() {
        const {classes, error, loading} = this.props;
        const {details, datehappen} = this.state;

        if (error) {
            return (<div>Error! {error.message} </div>);
        }

        if (loading) {
            return <div>Loading ...</div>
        }

        return (
            <div>
                <Button onClick={this.handleClickOpen}>Consigner l'activité</Button>
                <Dialog open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Détails de l'activité</DialogTitle>
                    <DialogContent>
                        <form className={classes.container} noValidate>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="type">Type d'activité</InputLabel>
                                <Select
                                    value={this.state.type}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'type',
                                        id: 'type-task',
                                    }}>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="Appel">Appel</MenuItem>
                                    <MenuItem value="Email">Email</MenuItem>
                                    <MenuItem value="RDV">RDV</MenuItem>
                                    <MenuItem component="span" value="fichier">Fichier</MenuItem>
                                    <MenuItem value="Status">Status</MenuItem>
                                </Select>

                                <TextField
                                    id="date"
                                    label="date"
                                    type="date"
                                    value={datehappen}
                                    onChange={(e) => {
                                        this.onChange(e.target.value, 'datehappen')
                                    }}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}/>

                                {this.state.type === 'Status' &&
                                <FormControl>
                                    <InputLabel htmlFor="type">Type de status</InputLabel>
                                    <Select
                                        value={this.state.status}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            name: 'status',
                                            id: 'type-status',
                                        }}>
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="demande reçu">Demande reçue</MenuItem>
                                        <MenuItem value="envoye au pro">Envoyé au pro</MenuItem>
                                        <MenuItem value="envoye au client">Envoyé au client</MenuItem>
                                        <MenuItem value="accepter">Accepter</MenuItem>
                                        <MenuItem value="Intervention planifier">Intervention planifiée</MenuItem>
                                        <MenuItem value="Intervention faite">Intervention faite</MenuItem>
                                        <MenuItem value="feed back">feed back</MenuItem>
                                        <MenuItem value="facture payé">Facture payée</MenuItem>
                                        <MenuItem value="demande abandonné">Demande abandonnée</MenuItem>
                                    </Select>
                                </FormControl>}

                                {this.state.type === 'fichier' &&
                                <div>
                                    <Button
                                        variant="contained"
                                        component="label">
                                        Upload File
                                        <input
                                            value={this.state.file}
                                            onChange={this.handleChange}
                                            type="file"
                                            style={{display: "none"}}/>
                                    </Button>
                                </div>
                                }
                                <TextField
                                    id="standard-textarea"
                                    label="Détails"
                                    value={details}
                                    onChange={(e) => {
                                        this.onChange(e.target.value, 'details')
                                    }}
                                    multiline
                                    className={classes.textField}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">Annuler</Button>
                        <Button onClick={this.handleSubmit} color="primary">Envoyer</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        adminInfo: state.datasReducer.adminInfo,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error
    })
}

export default withStyles(styles)(connect(mapStateToProps)(FormDialogActivity));
