import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import { API_URL } from "../../config.js";

const styles = theme => ({
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400,
    },
    modal: {
        marginTop: '10px',
        marginLeft: '10px'
    },
    selected: {
      width : '70%',
      marginTop: '10px',
      marginLeft: '10px'
    }
});

class FormDialogTask extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            Open: false
        };

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    handleClickOpen() {
        this.setState({Open: true})
    }

    handleClose() {
        this.setState({Open: false})
    }

      onDelete() {
        var data = new FormData();
        data.append("taskid", this.props.task.TasksID);

        return fetch(API_URL + '/task/taskdelete.php', {
          method: 'POST',
          headers: {'X-ACCESS-TOKEN': JSON.parse(localStorage.getItem('user')).AccessToken},
          body: data
        }).then(response => {
          return response.json()
        }).then(json => {
          this.handleClose();
          window.location.reload();
          return json;
        })
      }

    render() {
        const {classes, error, loading} = this.props;

        if (error) {
            return (<div>Error! {error.message} </div>);
        }

        if (loading) {
            return <div>Loading ...</div>
        }

        return (
            <div>
                <Button onClick={this.handleClickOpen} className={classes.modal} color="primary" variant="contained">Supprimer</Button>
                <Dialog open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                  <DialogTitle id="alert-dialog-title">SUPPRIMER</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <Typography>Etes-vous sûr de vouloir SUPPRIMER cette tâche ?</Typography>
                    <Typography>Tâche : {this.props.task.Title}</Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose} color="primary">Annuler</Button>
                    <Button onClick={(e) => this.onDelete()} color="primary" autoFocus>Confirmer</Button>
                  </DialogActions>
                </Dialog>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return ({
        loading: state.datasReducer.loading,
        error: state.datasReducer.error
    })
}

export default withStyles(styles)(connect(mapStateToProps)(FormDialogTask));
