import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import {postDatas} from "../../actions/postData.actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {Editor} from '@tinymce/tinymce-react';
import { API_URL } from "../../config.js";

const styles = theme => ({
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
    },
    cancel: {
        marginTop: '10px',
        marginLeft: '10px'
    }
});

class ModalCancel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            Open: false,
            comment: '',
            contentDevis: "Bonjour<br />"
                + "Je suis au regret de vous informer que le plaisancier a choisi un autre prestataire pour cette intervention.<br/><br/>"
                + "Cependant, je vous remercie pour votre collaboration et n’hésiterai pas à vous communiquer d’autres demandes dans le futur.<br/><br/>"
                + "Au plaisir,<br/>"
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit() {
        const data1 = new FormData();
        data1.append("inqueryid", this.props.InqueryID);
        data1.append("inquerystatus", 7);
        data1.append("cancelcomment", this.state.comment);
        this.props.dispatch(postDatas(API_URL + '/inquerychange.php', data1));

        // if (this.props.companies) {
        //   this.props.companies.map((company) => {
        //       const data = new FormData();
        //       data.append("companyid", company.CompanyID);
        //       data.append("message", this.state.contentDevis);
        //       this.props.dispatch(postDatas(API_URL + "/inquery/inquerysendcancelemail.php", data));
        //   });
        // }
        this.handleClose();
    }

    handleEditorChange(content) {
        this.setState({content});
    }

    handleClickOpen() {
        this.setState({Open: true})
    }

    onChange(value, key) {
        this.setState({[key]: value});
    };

    handleClose() {
        this.setState({Open: false})
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        const {classes, error, loading} = this.props;

        if (error) {
            return (<div>Error! {error.message} </div>);
        }

        if (loading) {
            return <div>Loading ...</div>
        }

        return (
            <div>
                <Button variant="contained" color="primary" onClick={this.handleClickOpen} className={classes.cancel}>Annuler la demande</Button>
                <Dialog open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Raison de l'annulation</DialogTitle>
                    <DialogContent>
                        <form className={classes.container}>
                            <FormControl className={classes.formControl}>

                            {/* <Editor apiKey='roemw219zpkel1zj9oi6f82wz7xym6tycg603tpiqr6dbmic'
                                            className={classes.editorStyle} value={this.state.contentDevis}
                                            onEditorChange={this.handleEditorChange}/> */}

                            <TextField
                            id="standard-textarea"
                            label="Commentaire"
                            value={this.state.comment}
                            onChange={(e) => {this.onChange(e.target.value, 'comment')}}
                            multiline
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            rows="5"/>
                            </FormControl>

                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">Annuler</Button>
                        <Button onClick={this.handleSubmit} color="primary">Envoyer</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        loading: state.datasReducer.loading,
        error: state.datasReducer.error
    })
}

export default withStyles(styles)(connect(mapStateToProps)(ModalCancel));
