import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {postDatas} from "../../actions/postData.actions";
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Avatar from '@material-ui/core/Avatar';
import StarRate from '@material-ui/icons/Star';
import {history} from '../../helpers';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import Pagination from "material-ui-flat-pagination";
import TablePagination from '@material-ui/core/TablePagination';
import MoreVert from '@material-ui/icons/MoreVert';
import Layout from '../common/Layout';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { API_URL } from '../../config.js'

const styles = theme => ({
  picture: {
    marginRight: '20px'
  },
  star: {
    color: '#fec107'
  },
  toright: {
    float: 'right',
    display:' flex'
  },
  icons: {
    marginBottom: '10px',
  },
  button: {
    marginTop:'10px'
  },
  ratingcomment: {
    marginTop: '20px',
  },
  plus: {
    display: 'flex',
    verticalAlign: 'top'
  },
  minus: {
    display: 'flex',
    verticalAlign: 'top'
  },
  lenght: {
    color: '#c4c4c4',
    fontsize: '12px'
  },
  more: {
    marginLeft: '20px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  delete: {
    position: 'absolute',
    right: 150,
    padding: '10px',
    border: '1px solid #c4c4c4'
  },
  raison: {
    fontSize: '22px',
    marginBottom: '10px'
  }
})

class ReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 25,
      isHover: {}
    };
    this.displayStar = this.displayStar.bind(this);
  }

  componentWillMount() {
    var data = new FormData();
    data.append("companyid", this.props.CompanyID);

    this.props.dispatch(fetchDatasWithName(API_URL + '/ratings/ratingreportget.php', 'ratings', 'POST', data));
  }

  displayStar(note) {
    const {classes} = this.props;
      if (note == "1") {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate/>
        <StarRate/>
        <StarRate/>
        <StarRate/>
      </div>)
      } else if (note == "2") {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate/>
        <StarRate/>
        <StarRate/>
      </div>)
      } else if (note == "3") {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate/>
        <StarRate/>
      </div>)
      } else if (note == "4") {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate/>
      </div>)
      } else {
        return (
      <div>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
        <StarRate className={classes.star}/>
      </div>)
      }
  }

  gotoUser(UserID) {
    history.push('/info-user/' + UserID);
  }

  handleClick(offset) {
    this.setState({ offset });
  }

  handleChangePage = (event, page) => {
      this.setState({page});
  };

  handleChangeRowsPerPage = event => {
      this.setState({rowsPerPage: event.target.value});
  };

  handleMouseEnter = index => {
    this.setState(prevState => {
      return { isHover: { ...prevState.isHover, [index]: true } };
    });
  };

  handleMouseLeave = index => {
    this.setState(prevState => {
      return { isHover: { ...prevState.isHover, [index]: false } };
    });
  };

  deleteCommentaire = (ratingid) => {
    var data = new FormData();
    data.append("ratingid", ratingid);

    return fetch(API_URL + 'ratings/ratingdelete.php', {
      method: 'POST',
      headers: {'X-ACCESS-TOKEN': JSON.parse(localStorage.getItem('user')).AccessToken},
      body: data
    }).then(response => {
      return response.json();
    }).then(json => {
      window.location.reload();
      return json
    })
  }

  render() {
    const {classes, loading, error} = this.props;
    const count = this.props.ratings && this.props.ratings.length;


    if (error) {
      return <div>Error! {error.message}</div>
    }

    if (loading || !this.props.ratings) {
      return <div> Loading !!!</div>
    }

    return (
      <Layout>
      <Grid container spacing={24}>
      <Grid item xs={12} md={12}>
      <Card>
        <CardContent>
          <h3>Commentaires reporté par nos utilisateurs</h3>
        </CardContent>
      {this.props.ratings && this.props.ratings.ratings && this.props.ratings.ratings.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((n, i) => {
        return (
          <CardContent key={i}>
          <Divider className={classes.icons} color="primary"/>
            <Grid container>
              <Grid item xs={12} sm={3}>
                <Typography >
                {n.UserPicture ? <img className={classes.picture} alt="user picture" src={API_URL + "/profilepictures/" + n.UserPicture} width="50" height="50"/> : <Avatar className={classes.picture} width="50" height="50"/>}
                {n.FirstName} {n.LasterName}</Typography>
                <Button className={classes.button} variant="contained" color="primary" onClick={() => {this.gotoUser(n.UserID)}}>Voir l'utilisateur</Button>
              </Grid>

              <Grid item xs={12} sm={8}>
                <div className={classes.toright}>
                  {this.displayStar(n.RatingStar)}

                  <Child
                  onMouseEnter={() => this.handleMouseEnter(i)}
                  onMouseLeave={() => this.handleMouseLeave(i)}
                  isHovering={this.state.isHover[i]}
                  key={i}
                  props={this.props}
                  ratingid={n.RatingID}
                  toDelete={() => this.deleteCommentaire(n.RatingID)}/>

                </div>
                <Typography className={classes.raison}>Raison : {n.ReportComment}</Typography>
                <div className={classes.plus}>
                  <Add className={classes.icons}/>
                  <Typography>{n.RatingBest}</Typography>
                </div>
                <div className={classes.minus}>
                <Remove className={classes.icons}/>
                <Typography>{n.RatingLess}</Typography>
                </div>
                <Typography className={classes.ratingcomment}>{n.RatingComment}</Typography>
              </Grid>
            </Grid>

          </CardContent>
        )
      })}
      <TablePagination
          component="div"
          count={parseInt(count)}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{
              'aria-label': 'Previous Page',
              'color': 'inherit',
          }}
          nextIconButtonProps={{
              'aria-label': 'Next Page',
              'color': 'inherit',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
      </Card>
      </Grid>
      </Grid>
      </Layout>
    )
  }
}


function Child({ onMouseEnter, onMouseLeave, isHovering, props, ratingid, toDelete}) {

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <MoreVert/> {isHovering && <Button className={props.classes.delete} onClick={toDelete}>Supprimer le commentaire</Button>}
    </div>
  );
}


function mapStateToProps(state) {
  return ({
    ratings: state.datasReducer.ratings,
    loading: state.datasReducer.loading,
    error: state.datasReducer.error
  })
}

export default withStyles(styles)(connect(mapStateToProps)(ReportPage));
