import React, {Component} from 'react';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import Layout from '../common/Layout';
import {postDatas} from "../../actions/postData.actions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { API_URL } from "../../config.js";

const styles = () => ({
    cardStyle: {
        display: 'inline-block',
        width: '100%',
        verticalAlign: 'top',
        transitionDuration: '0.3s',
    },
    title: {
        fontWeight: 'bold'
    },
    inputTextField: {
        width: '40%',
        paddingLeft: '10px',
    },
    maincard: {
        width: '100%'
    },
    submit: {
        margin: '10px',
        minWidth: 200
    }
});


class UserDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            classes: '',
            userData: this.props.userData,
            boatSelected: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChange2 = this.onChange2.bind(this);
        this.onChange3 = this.onChange3.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onUploadPicture = this.onUploadPicture.bind(this);
    }

    componentWillMount() {
        const data = new FormData();
        data.append("userid", this.props.match.params.id);
        this.props.dispatch(fetchDatasWithName(API_URL + '/userandboatdetails.php', 'userData', 'POST', data));
    };

    componentWillReceiveProps = props => {
        if (props.userData !== this.props.userData) {
            this.setState({userData: props.userData});
        }
    };

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    onUploadPicture(event) {
        var data = new FormData();
        data.append("userid", this.state.userData.user.UserID);
        data.append("pdf", event.target.files[0]);

        this.props.dispatch(postDatas(API_URL + '/user/useruploaddocument.php', data));
    }

    handleClick(e) {
        this.refs.fileUploader.click();
    }

    onChange(value, key) {
        this.setState({
            userData: {
                ...this.state.userData,
                user: {
                    ...this.state.userData.user,
                    [key]: value
                }
            }
        });
    }

    onChange2 = (value, key) => {
        this.setState({
                userData: {
                    ...this.state.userData,
                    boat: {
                        ...this.state.userData.boat,
                        [0]: {
                            ...this.state.userData.boat[0],
                            [key]: value
                        }
                    }
                }
            },
        )
    }

    onChange3 = (value, key) => {
        this.setState({
            userData: {
                ...this.state.userData,
                boat: {
                    ...this.state.userData.boat,
                        inqueries: {
                            ...this.state.userData.inqueries,
                            [0]: {
                                ...this.state.userData.inqueries[0],
                                [key]: value
                            }
                        }
                    }
                }
            }
        
    )
    }

    handleSubmit() {
        const dataUser = new FormData();
        console.log('les infos de l\'user')
        console.log(this.state)
        dataUser.append('UserID', this.state.userData.user.UserID);
        dataUser.append('firstname', this.state.userData.user.FirstName);
        dataUser.append('lastname', this.state.userData.user.LasterName);
        dataUser.append('username', this.state.userData.user.UserName);
        dataUser.append('description', this.state.userData.user.Description);
        dataUser.append('birthday', this.state.userData.user.Birthday);
        dataUser.append('email', this.state.userData.user.EmailAddress);
        dataUser.append('phonenumber', this.state.userData.user.PhoneNumber);
        dataUser.append('fulladdress', this.state.userData.user.FullAddress);

        this.props.dispatch(postDatas(API_URL + '/userupdate.php', dataUser));

        const dataBoat = new FormData();

        if (this.state.userData.boat) {
            console.log('les infos du boat')
            console.log(this.state.userData.boat)
            dataBoat.append('userid', this.state.userData.user.UserID);
            dataBoat.append('boatid', this.state.userData.boat[this.state.boatSelected].BoatID);
            dataBoat.append('boatname', this.state.userData.boat[this.state.boatSelected].BoatName);
            dataBoat.append('type', this.state.userData.boat[this.state.boatSelected].BoatType);
            dataBoat.append('maker', this.state.userData.boat[this.state.boatSelected].Maker);
            dataBoat.append('model', this.state.userData.boat[this.state.boatSelected].BoatModel);
            dataBoat.append('year', this.state.userData.boat[this.state.boatSelected].BoatYear);

            dataBoat.append('enginemodel', this.state.userData.boat[this.state.boatSelected].Engine);
            dataBoat.append('enginemaker', this.state.userData.boat[this.state.boatSelected].EngineMaker);
            dataBoat.append('enginetype', this.state.userData.boat[this.state.boatSelected].EngineType);
            dataBoat.append('nbengine', this.state.userData.boat[this.state.boatSelected].NbEngine);
            dataBoat.append('enginepower', this.state.userData.boat[this.state.boatSelected].EnginePower);

            dataBoat.append('engineyear', this.state.userData.boat[this.state.boatSelected].EngineYear);
            dataBoat.append('engineserialnumber', this.state.userData.boat[this.state.boatSelected].EngineSerialNumber);
            dataBoat.append('boatserialnumber', this.state.userData.boat[this.state.boatSelected].BoatSerialNumber);
            dataBoat.append('BoatWidth', this.state.userData.boat[this.state.boatSelected].BoatWidth);
            dataBoat.append('BoatLength', this.state.userData.boat[this.state.boatSelected].BoatLength);
            dataBoat.append('comment', this.state.userData.boat[this.state.boatSelected].Comment1);
            dataBoat.append('lat', this.state.userData.boat[this.state.boatSelected].lat);
            dataBoat.append('lng', this.state.userData.boat[this.state.boatSelected].lng);
            dataBoat.append('fulladdress', this.state.userData.boat[this.state.boatSelected].FullAddress);
            dataBoat.append('insuranceprovider', this.state.userData.boat[this.state.boatSelected].InsuranceProvider);
            dataBoat.append('insuredgoods', this.state.userData.boat[this.state.boatSelected].InsuredGoods);
            dataBoat.append('insurancenotes', this.state.userData.boat[this.state.boatSelected].InsuranceNotes);
            dataBoat.append('contractexpirydate', this.state.userData.boat[this.state.boatSelected].ContractExpiryDate);
            dataBoat.append('annualprice', this.state.userData.boat[this.state.boatSelected].AnnualPrice);
            this.props.dispatch(postDatas(API_URL + '/boatupdateV3.php', dataBoat));
        }
    }


    render() {
        const {loading, error, classes} = this.props;
        const {userData} = this.state;

        if (error) {
            return (<div>error! {error.message} </div>);
        }

        if (loading || !this.props.userData) {
            return <div>Loading ...</div>
        }

        return (
            <div>
                <Layout>
                    <Card className={classes.maincard}>
                        <form onSubmit={this.handleSubmit}>
                            <CardContent className={classes.cardStyle}>
                                <h3 className={classes.title}>Information de l'utilisateur</h3>
                                <Avatar alt="" src={API_URL + '/profilepictures/' + userData.user.UserPicture}/>

                                <TextField
                                    id="outlined-name"
                                    label="Nom"
                                    value={userData.user.FirstName}
                                    onChange={(e) => {this.onChange(e.target.value, 'FirstName')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Prénom"
                                    value={userData.user.LasterName}
                                    onChange={(e) => {this.onChange(e.target.value, 'LasterName')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Nom d'utilisateur"
                                    value={userData.user.UserName}
                                    onChange={(e) => {this.onChange(e.target.value, 'UserName')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Date de naissance"
                                    value={userData.user.Birthday}
                                    onChange={(e) => {this.onChange(e.target.value, 'Birthday')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>


                                <TextField
                                    id="outlined-name"
                                    label="Email"
                                    value={userData.user.EmailAddress}
                                    onChange={(e) => {this.onChange(e.target.value, 'EmailAddress')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Téléphone"
                                    value={userData.user.PhoneNumber}
                                    onChange={(e) => {this.onChange(e.target.value, 'PhoneNumber')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Adresse"
                                    value={userData.user.FullAddress}
                                    onChange={(e) => {this.onChange(e.target.value, 'FullAddress')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Description de l'utilisateur"
                                    value={userData.user.Description}
                                    onChange={(e) => {this.onChange(e.target.value, 'Description')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    multiline
                                    rows="5"
                                    variant="outlined"
                                    fullWidth/>



                                <h3>Documents de l'utilisateur</h3>
                                <div className={classes.middle}>
                                    {userData && userData.user && userData.user.Document && userData.user.Document.map((n, i) => {
                                        return (
                                            <div key={i}>
                                                <a target="_blank" rel="noopener noreferrer" href={API_URL + "/user/userdocumentupload/" + n.DocumentUploadLink}>{n.DocumentUploadName}</a>
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                                <div className={classes.middle}>
                                    <input onChange={this.onUploadPicture} accept="pdf" type="file" ref="fileUploader" style={{display: "none"}}/>
                                    <Button variant="contained" color="primary" onClick={this.handleClick} className={classes.buttonPhoto}>Télécharger des documents</Button>
                                </div>
                            </CardContent>

                            {userData.boat &&
                            <CardContent className={classes.cardStyle}>
                                <h3 className={classes.title}>Détails du bateau</h3>

                                <TextField
                                    id="outlined-name"
                                    label="Nom du bateau"
                                    value={userData.boat[this.state.boatSelected].BoatName}
                                    onChange={(e) => {this.onChange2(e.target.value, 'BoatName')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Type du bateau"
                                    value={userData.boat[this.state.boatSelected].BoatType}
                                    onChange={(e) => {this.onChange2(e.target.value, 'BoatType')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Marque du bateau"
                                    value={userData.boat[this.state.boatSelected].Maker}
                                    onChange={(e) => {this.onChange2(e.target.value, 'Maker')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Modèle du bateau"
                                    value={userData.boat[this.state.boatSelected].BoatModel}
                                    onChange={(e) => {this.onChange2(e.target.value, 'BoatModel')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Année du bateau"
                                    value={userData.boat[this.state.boatSelected].BoatYear}
                                    onChange={(e) => {this.onChange2(e.target.value, 'BoatYear')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Immatriculation"
                                    value={userData.boat[this.state.boatSelected].BoatSerialNumber}
                                    onChange={(e) => {this.onChange2(e.target.value, 'BoatSerialNumber')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Marque du moteur"
                                    value={userData.boat[this.state.boatSelected].EngineMaker}
                                    onChange={(e) => {this.onChange2(e.target.value, 'EngineMaker')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Modèle du moteur"
                                    value={userData.boat[this.state.boatSelected].Engine}
                                    onChange={(e) => {this.onChange2(e.target.value, 'Engine')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Type du moteur"
                                    value={userData.boat[this.state.boatSelected].EngineType}
                                    onChange={(e) => {this.onChange2(e.target.value, 'EngineType')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Nombre de moteurs"
                                    value={userData.boat[this.state.boatSelected].NbEngine}
                                    onChange={(e) => {this.onChange2(e.target.value, 'NbEngine')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Puissance du / des moteurs"
                                    value={userData.boat[this.state.boatSelected].EnginePower}
                                    onChange={(e) => {this.onChange2(e.target.value, 'EnginePower')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Année du moteur"
                                    value={userData.boat[this.state.boatSelected].EngineYear}
                                    onChange={(e) => {this.onChange2(e.target.value, 'EngineYear')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Numéro de série du moteur"
                                    value={userData.boat[this.state.boatSelected].EngineSerialNumber}
                                    onChange={(e) => {this.onChange2(e.target.value, 'EngineSerialNumber')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Port d'attache"
                                    value={userData.boat[this.state.boatSelected].FullAddress}
                                    onChange={(e) => {this.onChange2(e.target.value, 'FullAddress')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Longueur"
                                    value={userData.boat[this.state.boatSelected].BoatLength}
                                    onChange={(e) => {this.onChange2(e.target.value, 'BoatLength')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Largeur"
                                    value={userData.boat[this.state.boatSelected].BoatWidth}
                                    onChange={(e) => {this.onChange2(e.target.value, 'BoatWidth')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Latitude"
                                    value={userData.boat[this.state.boatSelected].lat}
                                    onChange={(e) => {this.onChange2(e.target.value, 'lat')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Longitude"
                                    value={userData.boat[this.state.boatSelected].lng}
                                    onChange={(e) => {this.onChange2(e.target.value, 'lng')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <h3 className={classes.title}>Assurance</h3>

                                <TextField
                                    id="outlined-name"
                                    label="Compagnie d'assurance"
                                    value={userData.boat[this.state.boatSelected].InsuranceProvider}
                                    onChange={(e) => {this.onChange2(e.target.value, 'InsuranceProvider')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Date de fin de contrat"
                                    value={userData.boat[this.state.boatSelected].ContractExpiryDate}
                                    onChange={(e) => {this.onChange2(e.target.value, 'ContractExpiryDate')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Valeur des biens assurés"
                                    value={userData.boat[this.state.boatSelected].InsuredGoods}
                                    onChange={(e) => {this.onChange2(e.target.value, 'InsuredGoods')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Prime annuelle"
                                    value={userData.boat[this.state.boatSelected].AnnualPrice}
                                    onChange={(e) => {this.onChange2(e.target.value, 'AnnualPrice')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Commentaire"
                                    value={userData.boat[this.state.boatSelected].Comment1}
                                    onChange={(e) => {this.onChange2(e.target.value, 'Comment1')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    multiline
                                    rows="5"
                                    fullWidth/>

                                <TextField
                                    id="outlined-name"
                                    label="Programme de navigation et autres informations"
                                    value={userData.boat[this.state.boatSelected].InsuranceNotes}
                                    onChange={(e) => {this.onChange2(e.target.value, 'InsuranceNotes')}}
                                    className={classes.inputTextField}
                                    margin="normal"
                                    variant="outlined"
                                    multiline
                                    rows="5"
                                    fullWidth/>


                            </CardContent>
                            }
                            <CardContent>
                                <Button className={classes.submit} variant='contained' color='primary' onClick={() => {this.handleSubmit()}}>Enregistrer</Button>
                            </CardContent>
                        </form>
                    </Card>
                </Layout>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        userData: state.datasReducer.userData,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error
    })
}

export default withStyles(styles)(connect(mapStateToProps)(UserDetails));
