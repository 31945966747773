import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import {postDatas} from "../../actions/postData.actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { API_URL} from "../../config.js";

const styles = theme => ({
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400,
    },
    finish: {
        marginTop: '10px',
        marginLeft: '10px'
    }
});

class ModalFinish extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            Open: false,
            note: 0,
            description: '',
            companyid: '',
            companyname: ''
        };

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getCompanyByID = this.getCompanyByID.bind(this);
    }

    handleSubmit() {
        const data = new FormData();
        data.append("inqueryid", this.props.InqueryID);
        data.append("allcompanyid", this.state.companyid);

        this.props.dispatch(postDatas(API_URL + '/ratings/ratingtokencreate.php', data));

        const data1 = new FormData();
        data1.append("inqueryid", this.props.InqueryID);
        data1.append("inquerystatus", 5);
        this.props.dispatch(postDatas(API_URL + '/inquerychange.php', data1));
        this.handleClose();
    }

    handleClickOpen() {
        this.setState({Open: true})
    }

    onChange(value, key) {
        this.setState({[key]: value});
        if (key == 'companyid') {
          this.setState({companyname: this.getCompanyByID()})
        }
    };

    handleClose() {
        this.setState({Open: false})
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    getCompanyByID() {
      var CompanyName = "";
      if (this.props.companies) {
        CompanyName = this.props.companies.map(company => {
          if (company.CompanyID == this.state.companyid)
            return company.CompanyName;
        })
      }
      return CompanyName;
    }

    render() {
        const {classes, error, loading} = this.props;

        if (error) {
            return (<div>Error! {error.message} </div>);
        }

        if (loading) {
            return <div>Loading ...</div>
        }

        return (
            <div>
                <Button className={classes.finish} variant="contained" color="primary" onClick={this.handleClickOpen}>Terminer la demande</Button>
                <Dialog open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Quel Professionel à été choisi ?</DialogTitle>
                    <DialogContent>
                        <form className={classes.container}>
                            <FormControl className={classes.formControl}>
                            <TextField
                            id="outlined-pro"
                            variant="outlined"
                            margin="normal"
                            select
                            fullWidth
                            className={classes.textField}
                            label="Professionel"
                            value={this.state.companyid}
                            onChange={(e) => {this.onChange(e.target.value, 'companyid')}}>
                              {this.props.companies && this.props.companies.map(company => (
                                <MenuItem key={company.CompanyID} value={company.CompanyID}>
                                  {company.CompanyName}
                                </MenuItem>
                            ))}
                            </TextField>

                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">Annuler</Button>
                        <Button onClick={this.handleSubmit} color="primary">Envoyer</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        loading: state.datasReducer.loading,
        error: state.datasReducer.error
    })
}

export default withStyles(styles)(connect(mapStateToProps)(ModalFinish));
