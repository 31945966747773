import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/es/Typography/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { fetchDatasWithName } from "../../actions/fetchWithName.actions";
import ParkingDisponibility from "./ParkingDisponibility";
import { history } from '../../helpers';
import Card from '@material-ui/core/Card';
import { API_URL } from "../../config.js";

const optionsLng = [
 '0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '13.5', '14', '14.5', '15', '15.5'
];
const status = [
  {
    value: '1',
    label: 'Actif'
  },
  {
    value: '2',
    label: 'Inactif'
  }
];

const styles = () => ({
  cardStyle: {
    display: 'flex',
    flexFlow: 'row',
    verticalAlign: 'top',
    transitionDuration: '0.3s',
  },
  title: {
    fontWeight: 'bold'
  },
  inputTextField: {
    width: 'max-content',
    paddingLeft: '10px',
  },
  maincard: {
    width: '100%',
    paddingLeft: '30px'
  },
  flex: {
    display: 'flex',
    flexFlow: 'row',
    width: 'max-content'
  },
  divtextfield: {
    display: 'flex',
    flexDirection: 'column',
    height: 'max-content'
  },
  divcheckbox: {
    paddingLeft: '10px'
  },
  submit: {
    margin: '10px',
    minWidth: 200
  },
  todelete: {
      marginLeft: '10px',
  },
});

class ParkingDescription extends Component {

  constructor(props) {
    super(props);
    this.state = {
      classes: '',
      parkingData: this.props.parkingData,
      photoSelected: 0,
      Open: false,
      removeOpen: false,
      redirect: false,
      rating: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleRemoveOpen = this.handleRemoveOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.deleteParking = this.deleteParking.bind(this);
  };

  componentWillMount() {
    const data = new FormData();
    data.append("parkingid", this.props.id);
    this.props.dispatch(fetchDatasWithName(API_URL + '/parkingdetails.php', 'parkingData', 'POST', data));
    this.props.dispatch(fetchDatasWithName(API_URL + '/ratings/ratingparkingget.php', 'rating', 'POST', data));
  };

  componentWillReceiveProps = props => {
    if (props.parkingData !== this.props.parkingData) {
      this.setState({parkingData: props.parkingData});
    }
    if (props.rating !== this.props.rating) {
      if (!props.rating.moyenne) {
        props.rating.moyenne = 0.00
      }
      this.setState({rating: props.rating});
    }
  };

  setRedirect = () => {
    history.push('/info-user/' + this.props.parkingData.parking.UserID);
  };

  onChange(value, key) {
    if (key === 'moyenne') {
      this.setState({rating: {
        ...this.state.rating,
        moyenne: value
      }})
    } else {
      this.setState({
        parkingData: {
          ...this.state.parkingData,
          parking: {
            ...this.state.parkingData.parking,
            [key]: value
          }
        }
      });
    }
  };

  deleteParking() {
    let user = JSON.parse(localStorage.getItem('user'));
    const data = new FormData
    data.append("parkingid", this.state.parkingData.parking.ParkingID);
    data.append("UserID", this.state.parkingData.parking.UserID);

    return fetch(API_URL + '/parkingdelete.php', {
        method: 'POST',
        headers: {'X-ACCESS-TOKEN': user.AccessToken},
        body: data
    }).then(response => {
        return response.json();
    })
    .then(json => {
        history.push("/search-parking");
    })
  }

  handleClickOpen(value) {
    this.setState({
      Open: true,
      photoSelected: value
    })
  }

  handleRemoveOpen() {
    this.setState({removeOpen: true})
  }

  handleClose() {
    this.setState({Open: false})
    this.setState({removeOpen: false})
  }

  handleSubmit() {
    const {parkingData} = this.state;
    const data = new FormData();

    console.log('on test les infos')
    console.log(this.state)
    console.log(this.props.id)

    console.log(parkingData.parking.Electricity)
    data.append('parkingid', this.props.id);
    data.append('title', parkingData.parking.Title);
    data.append('description', parkingData.parking.Description);
    data.append('fulladdress', parkingData.parking.FullAddress);
    data.append('pricemonth', parkingData.parking.PricePerMonth);
    data.append('status', parkingData.parking.Status);
    data.append('cancellationpolicy', parkingData.parking.CancellationPolicy);
    data.append('length', parkingData.parking.Length);
    data.append('width', parkingData.parking.Width);
    data.append('height', parkingData.parking.Height);
    data.append('access247', parkingData.parking.Access247 === 1 ? 1 : 'false');
    data.append('electricity', parkingData.parking.Electricity === 1 ? 1 : 'false');
    data.append('water', parkingData.parking.Water === 1 ? 1 : 'false');
    data.append('securitycamera', parkingData.parking.SecurityCamera === 1 ? 1 : 'false');
    data.append('security', parkingData.parking.Security === 1 ? 1 : 'false');
    data.append('directaccess', parkingData.parking.DirectAccess === 1 ? 1 : 'false');
    data.append('lat', parkingData.parking.lat);
    data.append('lng', parkingData.parking.lng);

    return fetch(API_URL + '/parkingupdate.php', {
      method: 'POST',
      headers: {'X-ACCESS-TOKEN': JSON.parse(localStorage.getItem('user')).AccessToken},
      body: data
    }).then(response => {
      return response.json();
    }).then(json => {
      // window.location.reload();
      return json;
    })
  }

  render() {
    const {loading, error, classes} = this.props;
    const {parkingData, rating} = this.state;

    if (error) {
      return (<div>error! {error.message} </div>);
    }

    if (loading || !this.props.parkingData) {
      return <div>Loading ...</div>
    }

    console.log(rating.moyenne)

    return (
      <Card className={classes.maincard}>
      <form onSubmit={this.handleSubmit}>
      <div className={classes.flex}>
          <CardContent className={classes.cardStyle}>
              <div className={classes.flex}>
                <div className={classes.divtextfield}>
                  <TextField
                  id="outlined-name"
                  label="Titre"
                  value={parkingData.parking.Title}
                  onChange={(e) => {this.onChange(e.target.value, 'Title')}}
                  className={classes.inputTextField}
                  margin="normal"
                  variant="outlined"
                  fullWidth/>

                  <TextField
                  id="outlined-name"
                  label="Description"
                  multiline
                  rows="5"
                  value={parkingData.parking.Description}
                  onChange={(e) => {this.onChange(e.target.value, 'Description')}}
                  className={classes.inputTextField}
                  margin="normal"
                  variant="outlined"
                  fullWidth/>
                </div>

                <div className={classes.divtextfield}>
                  <TextField
                  id="outlined-name"
                  label="Adresse"
                  value={parkingData.parking.FullAddress}
                  onChange={(e) => {this.onChange(e.target.value, 'FullAddress')}}
                  className={classes.inputTextField}
                  margin="normal"
                  variant="outlined"
                  fullWidth/>

                  <TextField
                  id="outlined-name"
                  label="Prix par mois"
                  value={parkingData.parking.PricePerMonth}
                  onChange={(e) => {this.onChange(e.target.value, 'PricePerMonth')}}
                  className={classes.inputTextField}
                  margin="normal"
                  type="number"
                  variant="outlined"
                  fullWidth/>

                  <TextField
                  id="outlined-name"
                  label="Note générale"
                  value={rating.moyenne}
                  disabled
                  className={classes.inputTextField}
                  margin="normal"
                  type="number"
                  variant="outlined"
                  fullWidth/>
                </div>

                <div className={classes.divtextfield}>
                  <TextField
                  id="outlined-name"
                  label="Status"
                  value={parkingData.parking.Status}
                  onChange={(e) => {this.onChange(e.target.value, 'Status')}}
                  className={classes.inputTextField}
                  margin="normal"
                  variant="outlined"
                  select
                  fullWidth>
                  {status.map(stat => (
                    <MenuItem key={stat.value} value={stat.value}>
                    {stat.label}
                    </MenuItem>
                  ))}
                  </TextField>
                  <TextField
                  id="outlined-name"
                  label="Longueur"
                  value={parkingData.parking.Length}
                  onChange={(e) => {this.onChange(e.target.value, 'Length')}}
                  className={classes.inputTextField}
                  margin="normal"
                  variant="outlined"
                  select
                  fullWidth>
                  {optionsLng.map(option => (
                    <MenuItem key={option} value={option}>
                    {option}
                    </MenuItem>
                  ))}
                  </TextField>

                  <TextField
                  id="outlined-name"
                  label="Largeur"
                  value={parkingData.parking.Width}
                  onChange={(e) => {this.onChange(e.target.value, 'Width')}}
                  className={classes.inputTextField}
                  margin="normal"
                  variant="outlined"
                  select
                  fullWidth>
                  {optionsLng.map(option => (
                    <MenuItem key={option} value={option}>
                    {option}
                    </MenuItem>
                  ))}
                  </TextField>
                  <TextField
                  id="outlined-name"
                  label="Hauteur"
                  value={parkingData.parking.Height}
                  onChange={(e) => {this.onChange(e.target.value, 'Height')}}
                  className={classes.inputTextField}
                  margin="normal"
                  variant="outlined"
                  select
                  fullWidth>
                  {optionsLng.map(option => (
                    <MenuItem key={option} value={option}>
                    {option}
                    </MenuItem>
                  ))}
                  </TextField>
                </div>

                <div className={classes.divtextfield}>
                  <TextField
                  id="outlined-name"
                  label="Annulation"
                  value={parkingData.parking.CancellationPolicy}
                  onChange={(e) => {this.onChange(e.target.value, 'CancellationPolicy')}}
                  className={classes.inputTextField}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows="5"
                  fullWidth/>
                </div>
              </div>
              <div>
                <div>
                  <CardContent className={classes.cardStyle}>
                    {parkingData && parkingData.pictures && parkingData.pictures.map((n, i) => {
                      return (
                        <div key={i}>
                          <Button onClick={() => {this.handleClickOpen(i)}}>
                            <img width="200" height="200" alt="ParkingPictures" src={API_URL + '/parkingpictures/' + n.PictureURL}/>
                          </Button>
                          <Dialog open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                            <DialogContent>
                              <img width="700" height="700" alt="ParkingPictures" src={API_URL + '/parkingpictures/' + this.state.parkingData.pictures[this.state.photoSelected].PictureURL}/>
                            </DialogContent>
                          </Dialog>
                        </div>
                      )
                    })}
                  </CardContent>
                </div>
              </div>
            <div className={classes.divcheckbox}>
              <Typography><Checkbox color="primary" checked={parkingData.parking.Electricity !== "0"} onChange={() => {this.onChange(parkingData.parking.Electricity === "0" ? "1" : "0", 'Electricity')}}/>Electricité</Typography><br/>
              <Typography><Checkbox color="primary" checked={parkingData.parking.Water !== "0"} onChange={() => {this.onChange(parkingData.parking.Water === "0" ? "1" : "0", 'Water')}}/>Eau</Typography><br/>
              <Typography><Checkbox color="primary" checked={parkingData.parking.Access247 !== "0"} onChange={() => {this.onChange(parkingData.parking.Access247 === "0" ? "1" : "0", 'Access247')}}/>Accès 24/7</Typography><br/>
              <Typography><Checkbox color="primary" checked={parkingData.parking.Security !== "0"} onChange={() => {this.onChange(parkingData.parking.Security === "0" ? "1" : "0", 'Security')}}/>Sécurité</Typography><br/>
              <Typography><Checkbox color="primary" checked={parkingData.parking.DirectAccess !== "0"} onChange={() => {this.onChange(parkingData.parking.DirectAccess === "0" ? "1" : "0", 'DirectAccess')}}/>Acces Direct</Typography><br/>
              <Typography><Checkbox color="primary" checked={parkingData.parking.SecurityCamera !== "0"} onChange={() => {this.onChange(parkingData.parking.SecurityCamera === "0" ? "1" : "0", 'SecurityCamera')}}/>Caméra de sécurité</Typography><br/>
            </div>
          </CardContent>
      </div>
      <div>
      <TextField
      id="outlined-name"
      label="Latitude"
      value={parkingData.parking.lat}
      onChange={(e) => {this.onChange(e.target.value, 'lat')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      multiline
      rows="5"
      fullWidth/>
      <TextField
      id="outlined-name"
      label="Longitude"
      value={parkingData.parking.lng}
      onChange={(e) => {this.onChange(e.target.value, 'lng')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      multiline
      rows="5"
      fullWidth/>
        <Button variant="contained" color="primary" onClick={() => {this.setRedirect()}}>Aller au propriétaire du parking</Button>
        <Button className={classes.todelete} onClick={this.handleRemoveOpen} variant="contained" color="#d4d4d4">Supprimer la demande</Button>
          <Dialog
          open={this.state.removeOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">SUPPRIMER</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Etes-vous sûr de vouloir supprimer cette demande ?
                  </DialogContentText>
              </DialogContent>
          <DialogActions>
              <Button onClick={this.handleClose} color="primary">Annuler</Button>
              <Button onClick={this.deleteParking} color="primary" autoFocus>Confirmer</Button>
          </DialogActions>
      </Dialog>
      </div>
      <div>
        <CardContent>
          <ParkingDisponibility calendar={this.state.parkingData.orders}/>
        </CardContent>
      </div>
      <div>
        <CardContent>
          <Button className={classes.submit} variant='contained' color='primary' onClick={() => {this.handleSubmit()}}>Enregistrer</Button>
        </CardContent>
      </div>
    </form>
  </Card>
    )
  }
}

function mapStateToProps(state) {
  return ({
    parkingCalendar: state.datasReducer.parkingCalendar,
    parkingData: state.datasReducer.parkingData,
    rating: state.datasReducer.rating,
    loading: state.datasReducer.loading,
    error: state.datasReducer.error
  })
}

export default withStyles(styles)(connect(mapStateToProps)(ParkingDescription));
