import React, {Component} from 'react';
import {connect} from "react-redux";
import Moment from "react-moment";
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import Assignment from '@material-ui/icons/Assignment';
import Card from '@material-ui/core/Card';

import {history} from '../../helpers';
import Layout from '../common/Layout';
import ModalTasks from './ModalTasks';
import ModalTaskDelete from './ModalTaskDelete';
import ModalTasksModify from './ModalTaskModify';
import {fetchDatas} from "../../actions/fetch.actions";
import { API_URL } from "../../config.js";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    padding: 10,
    Height: '100%',
    flexWrap: 'nowrap',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingRight: 10
  },
  table1: {
    border: 1,
    borderStyle: 'solid',
    borderColor: '#d4d4d4',
    width: 450,
  },
  rounded: {
    display: 'block',
    borderRadius: '50%',
    margin: 'auto',
    marginTop: '10px'
  },
  tablerow: {
    display: 'flex',
    flexDirection: 'row',
    borderCollapse: 'separate',
  },
  tablecell: {
    borderBottom: 'none',
    display: 'block',
  },
  title: {
      fontSize: '20px',
      fontWeight: 500
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    width: '100%',
    height: '100%',
  },
  cellmodal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  celltitle: {
    textAlign: 'center',
  },
  displayHide: {
    display: 'none'
  },
  cardcontentpro: {
    maxHeight: '100%',
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    flexWrap: 'nowrap',
  },
  paddingTable: {
    paddingRight: 10
  },
  body: {
    overflowY: 'scroll',
    height: 600,
    maxHeight: 600
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  todelete: {
    marginTop: '10px',
    marginLeft: '10px'
  },
  icon: {
      marginRight: 25,
      fontSize: 40,
  },
  header: {
      display: 'inline',
      textTransform: 'uppercase'
  },
  modify: {
    display: 'flex'
  },
  modal: {
      display: 'flex',
      marginTop: '10px',
      marginLeft: '10px',
      '&:hover': {
        cursor: 'pointer'
      }
  },
});

class TaskPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classes: '',
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(fetchDatas(API_URL + '/task/taskgetall.php', 'tasks', 'POST'));
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toInquery(id) {
    history.push("/queries/" + id);
  }

  render() {
    const {classes, error, loading, tasks} = this.props;

    if (error) {
      return (<div>Error</div>);
    }

    if (loading || !tasks) {
      return <div>Loading !</div>
    }

    return (
      <Layout>
      <div className={classes.root}>
      <Grid >
      <div className={classes.title}>
      <Assignment color="primary" className={classes.icon}/>
      <Typography className={classes.header} color="inherit" noWrap>Toutes les tâches</Typography>
      </div>
      <div>
      <Card className={classes.cardPro}>
      <Grid container spacing={24}>
      <Grid item xs={12}>
        <ModalTasks/>
      <CardContent className={classes.cardcontentpro}>
        <div className={classes.root}>
          <div className={classes.table}>
            <Table className={classes.table1}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.celltitle}>
                    <Typography className={classes.title} color="primary">A faire</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks && tasks.todo && tasks.todo.map((n , i) => {
                  return (<TableRow key={i}>
                    <TableCell className={classes.tablecell}>
                      <Paper className={classes.paper} elevation={1}>
                        <Typography>{n.Title}</Typography>
                        <Typography>{n.TasksDescription}</Typography>
                        <Typography>Date d'échéance : <Moment format="DD/MM/YYYY">{n.DueDate}</Moment></Typography>
                        <Typography>Utilisateur : {n.username}</Typography>
                        {n.company && <Link to={"/pro-details/" + n.CompanyID}>Entreprise : {n.company.CompanyName}</Link>}
                        <div className={classes.modify}>
                      {n.InqueryID != "0" ? <Button color="primary" variant="contained" onClick={(e) => this.toInquery(n.InqueryID)} className={classes.modal}>Voir la demande</Button> : null}
                      <ModalTasksModify task={n}/>
                      <ModalTaskDelete task={n}/>
                        </div>

                      </Paper>
                    </TableCell>
                  </TableRow>
                )
                })}
              </TableBody>
            </Table>
          </div>
        </div>
        <div className={classes.root}>
          <div className={classes.table}>
          <Table className={classes.table1}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.celltitle}>
                  <Typography color="primary" className={classes.title}>En cours</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks && tasks.inprogress && tasks.inprogress.map((n, i) => {
                return (<TableRow key={i}>
                  <TableCell className={classes.tablecell}>
                    <Paper className={classes.paper} elevation={1}>
                      <Typography>{n.Title}</Typography>
                      <Typography>{n.TasksDescription}</Typography>
                      <Typography>Date d'échéance : <Moment format="DD/MM/YYYY">{n.DueDate}</Moment></Typography>
                      <Typography>Utilisateur : {n.username}</Typography>
                        {n.company && <Link to={"/pro-details/" + n.CompanyID}>Entreprise : {n.company.CompanyName}</Link>}
                      <div className={classes.modify}>
                      {n.InqueryID != "0" ? <Button color="primary" variant="contained" onClick={(e) => this.toInquery(n.InqueryID)} className={classes.modal}>Voir la demande</Button> : null}
                      <ModalTasksModify task={n}/>
                        <ModalTaskDelete task={n}/>
                      </div>

                    </Paper>
                  </TableCell>
                </TableRow>
              )
              })}
            </TableBody>
          </Table>
          </div>
        </div>
        <div className={classes.root}>
          <div className={classes.table}>
          <Table className={classes.table1}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.celltitle}>
                  <Typography color="primary" className={classes.title}>Terminé</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks && tasks.finish && tasks.finish.map((n , i) => {
                return (<TableRow key={i}>
                  <TableCell className={classes.tablecell}>
                    <Paper className={classes.paper} elevation={1}>
                      <Typography>{n.Title}</Typography>
                      <Typography>{n.TasksDescription}</Typography>
                      <Typography>Date d'échéance : <Moment format="DD/MM/YYYY">{n.DueDate}</Moment></Typography>
                      <Typography>Utilisateur : {n.username}</Typography>
                        {n.company && <Link to={"/pro-details/" + n.CompanyID}>Entreprise : {n.company.CompanyName}</Link>}
                      <div className={classes.modify}>
                      {n.InqueryID != "0" ? <Button color="primary" variant="contained" onClick={(e) => this.toInquery(n.InqueryID)} className={classes.modal}>Voir la demande</Button> : null}
                      <ModalTasksModify task={n}/>
                        <ModalTaskDelete task={n}/>
                      </div>

                    </Paper>
                  </TableCell>
                </TableRow>
              )
              })}
            </TableBody>
          </Table>
          </div>
        </div>
        <div className={classes.root}>
          <div className={classes.table}>
          <Table className={classes.table1}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.celltitle}>
                  <Typography color="primary" className={classes.title}>Annuler</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks && tasks.cancel && tasks.cancel.map((n , i) => {
                return (<TableRow key={i}>
                  <TableCell className={classes.tablecell}>
                    <Paper className={classes.paper} elevation={1}>
                      <Typography>{n.Title}</Typography>
                      <Typography>{n.TasksDescription}</Typography>
                      <Typography>Date d'écheance : <Moment format="DD/MM/YYYY">{n.DueDate}</Moment></Typography>
                      <Typography>Utilisateur : {n.username}</Typography>
                        {n.company && <Link to={"/pro-details/" + n.CompanyID}>Entreprise : {n.company.CompanyName}</Link>}
                      <div className={classes.modify}>
                      {n.InqueryID != "0" ? <Button color="primary" variant="contained" onClick={(e) => this.toInquery(n.InqueryID)} className={classes.modal}>Voir la demande</Button> : null}
                      <ModalTasksModify task={n}/>
                      <ModalTaskDelete task={n}/>
                      </div>
                    </Paper>
                  </TableCell>
                </TableRow>
              )
              })}
            </TableBody>
          </Table>
          </div>
        </div>
      </CardContent>
      </Grid>
      </Grid>
      </Card>
      </div>
      </Grid>
      </div>
      </Layout>
    )
  }
}

TaskPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return ({
    tasks: state.datasReducer.tasks,
    loading: state.datasReducer.loading,
    error: state.datasReducer.error,
  });
}

export default withStyles(styles)(connect(mapStateToProps)(TaskPage));
