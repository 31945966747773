import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { fetchDatasWithName } from "../../actions/fetchWithName.actions";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { history } from '../../helpers';
import { API_URL } from "../../config.js";

const styles = () => ({
  cardStyle: {
    display: 'inline-block',
    width: '30vw',
    verticalAlign: 'top',
    transitionDuration: '0.3s',
  },

  service: {
    display: 'flex',
    flexFlow: 'row',
  },
  cardStyleService2: {
    display: 'inline-block',
    verticalAlign: 'top',
    transitionDuration: '0.3s',
  },
  cardStyleService3: {
    display: 'inline-flex',
    flexDirection: 'column'
  },
  titreService: {
    fontweight: 'bold',
  },
  inputTextField: {},
  title: {
    fontWeight: 'bold'
  },
  submit: {
    margin: '10px',
    minWidth: 200
  }
});

class ProInfoNotCreate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      classes: '',
      proData: this.props.proData
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  componentWillMount() {
    const data = new FormData();
    data.append("companyid", this.props.CompanyID);
    this.props.dispatch(fetchDatasWithName(API_URL + '/company/companydetails.php', 'proData', 'POST', data));
    this.setState({proData: this.props.proData});
  };

  componentWillReceiveProps = props => {
    if (props.proData !== this.props.proData) {
      this.setState({proData: props.proData});
    }
  };

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]: value});
  };

  onChange(value, key) {
    this.setState((previousState) => {
      const proData = previousState.proData;
      return {proData: {...proData, [key]: value}}
    });
  };

  handleSubmit() {
    console.log('on check les infos')
    console.log(this.state)
    const data = new FormData();
    data.append("CompanyName", this.state.proData.CompanyName);
    data.append("Adresse", this.state.proData.Adresse);
    data.append("Ville", this.state.proData.Ville);
    data.append("Code_postal", this.state.proData.Code_postal);
    data.append("Email", this.state.proData.Email);
    data.append("Phone1", this.state.proData.Phone1);
    data.append("Phone2", this.state.proData.Phone2);
    data.append("Fax", this.state.proData.Fax);
    data.append("Description", this.state.proData.Description);
    data.append("Website", this.state.proData.Website);
    data.append("Facebook", this.state.proData.Facebook);
    data.append("Twitter", this.state.proData.Twitter);
    data.append("Instagram", this.state.proData.Instagram);
    data.append("Youtube", this.state.proData.Youtube);
    data.append("Siren", this.state.proData.Siren);
    data.append("Siret", this.state.proData.Siret);
    data.append("Iban", this.state.proData.iban);
    data.append("VatID", this.state.proData.Vatid);
    data.append("lat", this.state.proData.Latitude);
    data.append("lng", this.state.proData.Longitude)
    data.append("Note", this.state.proData.Note);
    data.append("Commentaire", this.state.proData.Commentaire);

    data.append("PrenomRepresentantLegal", this.state.proData.PrenomRepresentantLegal);
    data.append("NomRepresentantLegal", this.state.proData.NomRepresentantLegal);
    data.append("AdresseRepresentantLegal", this.state.proData.AdresseRepresentantLegal);
    data.append("VilleRepresentantLegal", this.state.proData.VilleRepresentantLegal);
    data.append("PaysRepresentantLegal", this.state.proData.PaysRepresentantLegal);
    data.append("RegionRepresentantLegal", this.state.proData.RegionRepresentantLegal);
    data.append("BirthdayRepresentantLegal", this.state.proData.BirthdayRepresentantLegal);        //Service
    data.append("Service_BoatSeller", this.state.proData.Service_BoatSeller);
    data.append("Service_Seller", this.state.proData.Service_Seller);
    data.append('Service_SellerEngine', this.state.proData.Service_SellerEngine);
    data.append("Service_SellerTrailer", this.state.proData.Service_SellerTrailer);
    data.append("Service_RepairEngine_InBord", this.state.proData.Service_RepairEngine_InBord);
    data.append("Service_RepairEngine_HorsBord", this.state.proData.Service_RepairEngine_HorsBord);
    data.append("Service_Rent", this.state.proData.Service_Rent);
    data.append("Service_Chandlery", this.state.proData.Service_Chandlery);
    data.append("Service_Repair", this.state.proData.Service_Repair);
    data.append("Service_SellEngineSpareParts", this.state.proData.Service_SellEngineSpareParts);
    data.append("Service_Maker", this.state.proData.Service_Maker);
    data.append("Service_SailManufacturerAndRepair", this.state.proData.Service_SailManufacturerAndRepair);
    data.append("Service_RepairElectronics", this.state.proData.Service_RepairElectronics);
    data.append("Service_Electrics", this.state.proData.Service_Electrics);
    data.append("Service_SellerBoatLeather", this.state.proData.Service_SellerBoatLeather);
    data.append("Service_DryMarina", this.state.proData.Service_DryMarina);
    data.append("Service_HarbourSpots", this.state.proData.Service_HarbourSpots);
    data.append("Service_HarbourSpot", this.state.proData.Service_HarbourSpot);
    data.append("Service_Cleaner", this.state.proData.Service_Cleaner);
    data.append("Service_BoatTowing", this.state.proData.Service_BoatTowing);
    data.append("Service_BoatRecycling", this.state.proData.Service_BoatRecycling);
    data.append("Service_SellerFishingEquipment", this.state.proData.Service_SellerFishingEquipment);
    data.append("Service_BoatLifting", this.state.proData.Service_BoatLifting);
    data.append("Service_BoatGarage", this.state.proData.Service_BoatGarage);
    data.append("Service_CareTaker", this.state.proData.Service_CareTaker);
    data.append("Service_Insurance", this.state.proData.Service_Insurance);
    data.append("Service_Expertise", this.state.proData.Service_Expertise);
    data.append("Service_BoatTransportation", this.state.proData.Service_BoatTransportation);
    data.append("Service_MarineCarpentry", this.state.proData.Service_MarineCarpentry);
    data.append("Service_StainlessSteelSpecialist", this.state.proData.Service_StainlessSteelSpecialist);
    data.append("Service_NavalArchitect", this.state.proData.Service_NavalArchitect);
    data.append("Service_UnderwaterWorksAndDivers", this.state.proData.Service_UnderwaterWorksAndDivers);
    data.append("Service_HeatingSpecialist", this.state.proData.Service_HeatingSpecialist);
    data.append("Service_FireProtection", this.state.proData.Service_FireProtection);
    data.append("Service_InflatableReparation", this.state.proData.Service_InflatableReparation);
    data.append("Service_Photographer", this.state.proData.Service_Photographer);
    data.append("Service_Videomaker", this.state.proData.Service_Videomaker);
    data.append("Service_BoatLicense", this.state.proData.Service_BoatLicense);
    data.append("Service_SaillingSchool", this.state.proData.Service_SaillingSchool);
    data.append("Service_GateringOnBoard", this.state.proData.Service_GateringOnBoard);
    data.append("Service_EventPlanning", this.state.proData.Service_EventPlanning);
    data.append("Service_Stratification", this.state.proData.Service_Stratification);
    data.append("Service_Carpentry", this.state.proData.Service_Carpentry);
    data.append("Service_Mature_Ajustment", this.state.proData.Service_Mature_Ajustment);
    data.append("Service_New_Boat", this.state.proData.Service_New_Boat);
    data.append("Service_Boat_Transport", this.state.proData.Service_Boat_Transport);
    data.append("Service_BoatBuilding", this.state.proData.Service_BoatBuilding);
    data.append("Service_SailingCleaning", this.state.proData.Service_SailingCleaning);
    data.append("Service_PaintingHull", this.state.proData.Service_PaintingHull);
    data.append("Service_ManufactingRigging", this.state.proData.Service_ManufactingRigging);
    //add update company not create php
    let user = JSON.parse(localStorage.getItem('user'));
    if (this.state.proData.CompanyNoCreateID) {
      data.append("CompanyNoCreateID", this.state.proData.CompanyNoCreateID);
      return fetch(API_URL + '/company/companynotcreateupdatedetails.php', {
        method: 'POST',
        headers: {'X-ACCESS-TOKEN': user.AccessToken},
        body: data
      }).then(response => {
        return response.json();
      }).then(json => {
        history.push(window.location.reload());
      })
    }
    if (this.state.proData.CompanyCreateID) {
      data.append("CompanyCreateID", this.state.proData.CompanyCreateID);
      return fetch(API_URL + '/company/companyupdatedetails.php', {
        method: 'POST',
        headers: {'X-ACCESS-TOKEN': user.AccessToken},
        body: data
      }).then(response => {
        return response.json();
      }).then(json => {
        history.push('/search');
      })
    }
  }

  render() {
    const {classes, loading, error} = this.props;

    if (error) {
      return (<div>Error! {error.message} </div>);
    }

    if (loading || !this.props.proData) {
      return <div>Loading ...</div>
    }

    return (
      <form onSubmit={this.handleSubmit}>
      <Card>
      <CardContent className={classes.cardStyle}>
      <h3 className={classes.title}>Info de l'entreprise</h3>

      <TextField
      id="outlined-name"
      label="CompanyName"
      placeholder="Nom de l'entreprise"
      required
      value={this.state.proData.CompanyName}
      onChange={(e) => {this.onChange(e.target.value, 'CompanyName')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Adresse"
      placeholder="Adresse"
      value={this.state.proData.Adresse}
      onChange={(e) => {this.onChange(e.target.value, 'Adresse')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="ville"
      placeholder="Ville"
      value={this.state.proData.Ville}
      onChange={(e) => {this.onChange(e.target.value, 'Ville')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="codePostal"
      placeholder="Code postal"
      value={this.state.proData.Code_postal}
      onChange={(e) => {this.onChange(e.target.value, 'Code_postal')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>


      <TextField
      id="outlined-name"
      label="Téléphone"
      placeholder="Téléphone"
      value={this.state.proData.Phone1}
      onChange={(e) => {this.onChange(e.target.value, 'Phone1')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Mobile"
      placeholder="Mobile"
      value={this.state.proData.Phone2}
      onChange={(e) => {this.onChange(e.target.value, 'Phone2')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Email"
      placeholder="Email"
      value={this.state.proData.Email}
      onChange={(e) => {this.onChange(e.target.value, 'Email')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Fax"
      placeholder="Fax"
      value={this.state.proData.Fax}
      onChange={(e) => {this.onChange(e.target.value, 'Fax')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>


            <TextField
            id="outlined-lat"
            label="Latitude"
            placeholder="0"
            value={this.state.proData.Latitude}
            onChange={(e) => {this.onChange(e.target.value, 'Latitude')}}
            margin="normal"
            className={classes.inputTextField}
            variant="outlined"
            fullWidth/>

            <TextField
            id="outlined-lng"
            label="Longitude"
            placeholder="0"
            value={this.state.proData.Longitude}
            onChange={(e) => {this.onChange(e.target.value, 'Longitude')}}
            margin="normal"
            className={classes.inputTextField}
            variant="outlined"
            fullWidth/>

      <TextField
      id="outlined-name"
      label="Description"
      placeholder="Description"
      value={this.state.proData.Description}
      onChange={(e) => {this.onChange(e.target.value, 'Description')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      multiline
      rows="5"
      fullWidth/>

      <TextField
      id="outlined-note"
      label="Note"
      margin="normal"
      variant="outlined"
      value={this.state.proData.Note}
      fullWidth
      className={classes.inputTextField}
      placeholder="0/5"
      onChange={(e) => {this.onChange(e.target.value, 'Note')}}
      type="number"/>

      <TextField
       id="outlined-multiline-static"
       label="Commentaire"
       placeholder="Décrivez votre parking pour le rendre attractif"
       value={this.state.proData.Commentaire}
       onChange={(e) => {this.onChange(e.target.value, 'Commentaire')}}
       rows="5"
       className={classes.inputTextField}
       fullWidth
       multiline
       margin="normal"
       variant="outlined"
     />
      </CardContent>

      <CardContent className={classes.cardStyleService3}>
      <CardContent className={classes.cardStyle}>
      <h3 className={classes.title}>Media</h3>

      <TextField
      id="outlined-name"
      label="Site internet"
      placeholder="Site internet"
      value={this.state.proData.Website}
      onChange={(e) => {this.onChange(e.target.value, 'Website')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Facebook"
      placeholder="Facebook"
      value={this.state.proData.Facebook}
      onChange={(e) => {this.onChange(e.target.value, 'Facebook')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Twitter"
      placeholder="Twitter"
      value={this.state.proData.Twitter}
      onChange={(e) => {
        this.onChange(e.target.value, 'Twitter')
      }}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth
      />

      <TextField
      id="outlined-name"
      label="Instagram"
      placeholder="Instagram"
      value={this.state.proData.Instagram}
      onChange={(e) => {
        this.onChange(e.target.value, 'Instagram')
      }}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth
      />

      <TextField
      id="outlined-name"
      label="Youtube"
      placeholder="Youtube"
      value={this.state.proData.Youtube}
      onChange={(e) => {
        this.onChange(e.target.value, 'Youtube')
      }}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth
      />
      </CardContent>

      <CardContent className={classes.cardStyle}>
      <h3 className={classes.title}>Informations Legale</h3>

      <TextField
      id="outlined-name"
      label="Siren"
      placeholder="Siren"
      value={this.state.proData.Siren}
      onChange={(e) => {this.onChange(e.target.value, 'Siren')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Siret"
      placeholder="Siret"
      value={this.state.proData.Siret}
      onChange={(e) => {this.onChange(e.target.value, 'Siret')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="IBAN"
      disabled
      placeholder="IBAN"
      value={this.state.proData.Iban}
      onChange={(e) => {this.onChange(e.target.value, 'Iban')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Numéro de tva"
      placeholder="Numéro de tva"
      value={this.state.proData.Vatid}
      onChange={(e) => {this.onChange(e.target.value, 'Vatid')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>
      </CardContent>
      </CardContent>

      <CardContent className={classes.cardStyle}>
      <h3 className={classes.title}> Informations du représentant legal</h3>

      <TextField
      id="outlined-name"
      label="Prénom"
      placeholder="Prénom"
      value={this.state.proData.PrenomRepresentantLegal}
      onChange={(e) => {this.onChange(e.target.value, 'PrenomRepresentantLegal')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Nom de famille"
      placeholder="Nom de famille"
      value={this.state.proData.NomRepresentantLegal}
      onChange={(e) => {this.onChange(e.target.value, 'NomRepresentantLegal')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Adresse de représentant"
      placeholder="Adresse"
      value={this.state.proData.AdresseRepresentantLegal}
      onChange={(e) => {this.onChange(e.target.value, 'AdresseRepresentantLegal')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Ville du représentant"
      placeholder="Ville"
      value={this.state.proData.VilleRepresentantLegal}
      onChange={(e) => {this.onChange(e.target.value, 'VilleRepresentantLegal')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Région du représentant"
      placeholder="Region"
      value={this.state.proData.RegionRepresentantLegal}
      onChange={(e) => {this.onChange(e.target.value, 'RegionRepresentantLegal')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Pays de représentant"
      placeholder="Pays"
      value={this.state.proData.PaysRepresentantLegal}
      onChange={(e) => {this.onChange(e.target.value, 'PaysRepresentantLegal')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth/>

      <TextField
      id="outlined-name"
      label="Date de Naissance du représentant"
      placeholder="Date de naissance"
      type="date"
      value={this.state.proData.BirthdayRepresentantLegal}
      onChange={(e) => {this.onChange(e.target.value, 'BirthdayRepresentantLegal')}}
      className={classes.inputTextField}
      margin="normal"
      variant="outlined"
      fullWidth
      InputLabelProps={{
          shrink: true,
      }}/>
      </CardContent>

      <CardContent className={classes.cardStyleService2}>
      <h3>Services</h3>

      <div className={classes.service}>
      <CardContent className={classes.cardStyleService2}>
      <h4 className={classes.title}>Concession et magasins</h4>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Seller !== "0"}
      onChange={() => {this.onChange(this.state.proData.Service_Seller === "0" ? "1" : "0", 'Service_Seller')}}/>Vente de Bateau</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_SellerEngine !== "0"}
      onChange={() => {this.onChange(this.state.proData.Service_SellerEngine === "0" ? "1" : "0", 'Service_SellerEngine')}}/>Vente de Moteur</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_SellerTrailer !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_SellerTrailer === "0" ? "1" : "0", 'Service_SellerTrailer')
      }}/>Vente de remorques</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Rent !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Rent === "0" ? "1" : "0", 'Service_Rent')
      }}/>Location de
      bateau</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Chandlery !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Chandlery === "0" ? "1" : "0", 'Service_Chandlery')
      }}/>Accastillage</Typography><br/>


      </CardContent>
      <CardContent className={classes.cardStyleService2}>
      <h4 className={classes.title}>Fabrication et maintenance</h4>
      <Typography><Checkbox color="primary" checked={this.state.proData.Service_Repair !== "0"} onChange={() => {this.onChange(this.state.proData.Service_Repair === "0" ? "1" : "0", 'Service_Repair')}}/>Entretien de bateau</Typography><br/>

      <Typography><Checkbox color="primary" checked={this.state.proData.Service_RepairEngine_HorsBord !== "0"} onChange={() => {this.onChange(this.state.proData.Service_RepairEngine_HorsBord === "0" ? "1" : "0", 'Service_RepairEngine_HorsBord')}}/>Entretien de moteur Hors-Bord</Typography><br/>
      <Typography><Checkbox color="primary" checked={this.state.proData.Service_RepairEngine_InBord !== "0"} onChange={() => {this.onChange(this.state.proData.Service_RepairEngine_InBord === "0" ? "1" : "0", 'Service_RepairEngine_InBord')}}/>Entretien de moteur In-Bord</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_SellEngineSpareParts !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_SellEngineSpareParts === "0" ? "1" : "0", 'Service_SellEngineSpareParts')
      }}/>Vente pièces
      détachées</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_BoatBuilding !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_BoatBuilding === "0" ? "1" : "0", 'Service_BoatBuilding')
      }}/>Chantier de construction
      naval</Typography><br/>


      {/* <Typography><Checkbox color="primary" checked={this.state.proData.Service_Maker !== "0"} onChange={() => {this.onChange(this.state.proData.Service_Maker === "0" ? "1" : "0", 'Service_Maker')}}/>Chantier Naval</Typography><br/> */}
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_SailManufacturerAndRepair !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_SailManufacturerAndRepair === "0" ? "1" : "0", 'Service_SailManufacturerAndRepair')
      }}/>Voilerie</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_RepairElectronics !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_RepairElectronics === "0" ? "1" : "0", 'Service_RepairElectronics')
      }}/>Électronique</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Electrics !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Electrics === "0" ? "1" : "0", 'Service_Electrics')
      }}/>Électricité</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_SellerBoatLeather !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_SellerBoatLeather === "0" ? "1" : "0", 'Service_SellerBoatLeather')
      }}/>Sellerie nautique</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_BoatTransportation !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_BoatTransportation === "0" ? "1" : "0", 'Service_BoatTransportation')
      }}/>Convoyage</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_MarineCarpentry !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_MarineCarpentry === "0" ? "1" : "0", 'Service_MarineCarpentry')
      }}/> Charpente marine</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_StainlessSteelSpecialist !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_StainlessSteelSpecialist === "0" ? "1" : "0", 'Service_StainlessSteelSpecialist')
      }}/> Spécialiste Inox</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_NavalArchitect !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_NavalArchitect === "0" ? "1" : "0", 'Service_NavalArchitect')
      }}/> Architecte
      naval</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_UnderwaterWorksAndDivers !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_UnderwaterWorksAndDivers === "0" ? "1" : "0", 'Service_UnderwaterWorksAndDivers')
      }}/> Travaux sous-marins et plongeurs</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_HeatingSpecialist !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_HeatingSpecialist === "0" ? "1" : "0", 'Service_HeatingSpecialist')
      }}/> Chauffagiste</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_FireProtection !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_FireProtection === "0" ? "1" : "0", 'Service_FireProtection')
      }}/> Protection Incendie</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_InflatableReparation !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_InflatableReparation === "0" ? "1" : "0", 'Service_InflatableReparation')
      }}/> Réparation pneumatiques</Typography><br/>

      </CardContent>

      <CardContent className={classes.cardStyleService2}>
      <h4 className={classes.title}>Équipement Voilier</h4>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_SailingCleaning !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_SailingCleaning === "0" ? "1" : "0", 'Service_SailingCleaning')
      }}/>Nettoyage voile</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_PaintingHull !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_PaintingHull === "0" ? "1" : "0", 'Service_PaintingHull')
      }}/>Peinture et marquage sur coques ou voiles</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_ManufactingRigging !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_ManufactingRigging === "0" ? "1" : "0", 'Service_ManufactingRigging')
      }}/>Fabrication de gréements, espars
      et composite</Typography><br/>


      </CardContent>


      <CardContent className={classes.cardStyleService2}>
      <h4 className={classes.title}>Services</h4>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Insurance !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Insurance === "0" ? "1" : "0", 'Service_Insurance')
      }}/>Assurance </Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Expertise !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Expertise === "0" ? "1" : "0", 'Service_Expertise')
      }}/>Expertise
      maritime</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_DryMarina !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_DryMarina === "0" ? "1" : "0", 'Service_DryMarina')
      }}/>Place à sec</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_HarbourSpots !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_HarbourSpots === "0" ? "1" : "0", 'Service_HarbourSpots')
      }}/>Place à flot</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Cleaner !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Cleaner === "0" ? "1" : "0", 'Service_Cleaner')
      }}/>Nettoyage</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_BoatTowing !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_BoatTowing === "0" ? "1" : "0", 'Service_BoatTowing')
      }}/>Remorquage</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_BoatRecycling !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_BoatRecycling === "0" ? "1" : "0", 'Service_BoatRecycling')
      }}/>Déconstruction et
      recyclage</Typography><br/>


      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_SellerFishingEquipment !== "0"}
      onChange={() => {this.onChange(this.state.proData.Service_SellerFishingEquipment === "0" ? "1" : "0", 'Service_SellerFishingEquipment')
      }}/>Équipement de pêche</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_BoatLifting !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_BoatLifting === "0" ? "1" : "0", 'Service_BoatLifting')
      }}/> Levage Grutage</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_BoatGarage !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_BoatGarage === "0" ? "1" : "0", 'Service_BoatGarage')
      }}/>Gardiennage</Typography><br/>
      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_CareTaker !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_CareTaker === "0" ? "1" : "0", 'Service_CareTaker')
      }}/>Concierge</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Photographer !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Photographer === "0" ? "1" : "0", 'Service_Photographer')
      }}/>Photographe</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Videomaker !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Videomaker === "0" ? "1" : "0", 'Service_Videomaker')
      }}/>Vidéaste</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_BoatLicense !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_BoatLicense === "0" ? "1" : "0", 'Service_BoatLicense')
      }}/>Permis Bateau</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_SaillingSchool !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_SaillingSchool === "0" ? "1" : "0", 'Service_SaillingSchool')
      }}/>École de voile</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_GateringOnBoard !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_GateringOnBoard === "0" ? "1" : "0", 'Service_GateringOnBoard')
      }}/>Restauration à bord</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_EventPlanning !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_EventPlanning === "0" ? "1" : "0", 'Service_EventPlanning')
      }}/>Événementiel</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Stratification !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Stratification === "0" ? "1" : "0", 'Service_Stratification')
      }}/>Stratification</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Carpentry !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Carpentry === "0" ? "1" : "0", 'Service_Carpentry')
      }}/>Menuiserie</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Mature_Ajustment !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Mature_Ajustment === "0" ? "1" : "0", 'Service_Mature_Ajustment')
      }}/>Réglage mature</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_New_Boat !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_New_Boat === "0" ? "1" : "0", 'Service_New_Boat')
      }}/>Préparation bateau neuf</Typography><br/>

      <Typography><Checkbox color="primary"
      checked={this.state.proData.Service_Boat_Transport !== "0"}
      onChange={() => {
        this.onChange(this.state.proData.Service_Boat_Transport === "0" ? "1" : "0", 'Service_Boat_Transport')
      }}/>Transport de bateau</Typography><br/>

      </CardContent>
      </div>


      </CardContent>
      <CardContent>
      <Button className={classes.submit} variant='contained' color='primary' onClick={() => {this.handleSubmit()}}>Enregistrer</Button>
      </CardContent>
      </Card>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return ({
    proData: state.datasReducer.proData,
    loading: state.datasReducer.loading,
    error: state.datasReducer.error
  })
}

export default withStyles(styles)(connect(mapStateToProps)(ProInfoNotCreate));
