import React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {withStyles} from '@material-ui/core';
import {connect} from "react-redux";
import {fetchDatas} from "../../actions/fetch.actions";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {postDatas} from "../../actions/postData.actions";
import {bindActionCreators} from 'redux';
import { API_URL } from "../../config.js";

const styles = ({
    editorStyle: {
        height: "800px",
    },
    message: {
        color: "red",
        fontWeight: "bold",
    },
});

class MessageEditor extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            inquerieType: '',
            contentDevis: "Madame, Monsieur,<br/><br/>"
                + "Je vous contacte de la part d'un propriétaire d'un __model  qui souhaite obtenir "
                + "un devis pièces et main d'œuvre pour l'intervention ci-dessous.<br/><br/>"
                + "<b>INFORMATIONS SUR LE BATEAU :</b> <br/>"
                + "- Type de bateau : __type <br/>"
                + "- Modèle : __model <br/>"
                + "- Moteur  __engine <br/>"
                + "- Année du bateau : __year <br/>"
                + "- Port d'attache : __full_address <br/>"
                + "- Nom pour le devis : __first_name <br/><br/>"
                + "<b>TRAVAUX À EFFECTUER :</b> __message <br/>"
                + "L'intervention doit avoir lieu dans __deadlineWeek semaine(s).<br/><br/>"
                + "<b>QUI SOMMES-NOUS?</b><br/>"
                + "BoatOn Pro est une application gratuite qui simplifie la vie des plaisanciers. <br/>"
                + "Carnet d'entretien, intervention quelconque, place de parking ou assurance"
                + ", nous apportons des solutions innovantes aux problèmes rencontrés par les plaisanciers.<br/>"
                + "Vous trouverez plus d'informations sur notre site internet www.boaton.fr.<br/><br/>"
                + "<b>COMMENT ÇA MARCHE?</b><br/>"
                + "1. Vous saisissez votre devis en cliquant sur le lien suivant : <a href=\"https://pro.boaton.fr/#/devis/{+token+}\">CLIQUEZ ICI</a><br/>"
                + "2. Nous communiquons les devis obtenus au plaisancier et vous tenons au courant de son choix. <br/>"
                + "3. Nous coordonnons le rendez-vous avec le plaisancier.<br/><br/>"
                + "Des frais de service de 8.5% sur le montant total du devis s'appliquent dans le cas "
                + "où vous seriez choisi par le plaisancier. Merci de les inclure dans votre devis."
                + " Aucun frais ne vous sera demandé dans le cas où le plaisancier ne choisit pas votre offre.<br/><br/>"
                + "Afin de garantir un service optimal, seuls les 5 professionnels les mieux notés reçoivent les nouvelles"
                + " demandes des propriétaires de bateaux."
                + " Plus vous répondez rapidement à une demande, plus vous augmentez vos chances d'être contacté de nouveau.<br/><br/>"
                + "Je reste à votre disposition pour tout renseignement complémentaire !<br/>"
                + "Paul<br/>"
                + "E: paul@boaton.fr<br/>"
                + "T: +33 6 78 76 36 98 | www.boaton.fr",

            contentAssurance: "<div style='display: flex; flex-direction: column;'>Bonjour, <br/><br/>"
                + "Vous avez reçu une nouvelle demande d'assurance pour un __maker __model.<br/>"
                + "Connectez-vous sur votre espace professionnel pour y répondre.<br/><br/>"
                + "<a style='margin: auto; text-align: center; width: 250px; text-decoration:none ;background-color:#fec107; border: 1px solid #fec107; color: black; padding: 10px;' href='https://pro.boaton.fr/#/inqueries'>RÉPONDEZ MAINTENANT</a><br/><br/>"
                + "Je reste à votre disposition pour tout renseignement complémentaire !<br/><br/>"
                + "Paul<br/>"
                + "E: paul@boaton.fr<br/>"
                + "T: +33 6 78 76 36 98 | www.boaton.fr</div>",

        };
        this.handleEditorChangeDevis = this.handleEditorChangeDevis.bind(this);
        this.handleEditorChangeAssurance = this.handleEditorChangeAssurance.bind(this);
        this.sendMail = this.sendMail.bind(this);
    }

    calcDeadline(submission_date) {
        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        const todayFormatted = yyyy + '-' + mm + '-' + dd;

        const actualDate = new Date(todayFormatted).toJSON();
        const endDate = new Date(submission_date && submission_date.substring(0, 10)).toJSON();

        function date_diff_indays(d1, d2) {
            const diff = Date.parse(d2) - Date.parse(d1);
            return Math.floor(diff / 86400000);
        }
        return Math.floor((date_diff_indays(endDate, actualDate)) / 7);
    }

    // noinspection JSMethodCanBeStatic
    replaceAllContent(str, obj) {
        for (const key of Object.keys(obj)) {
            const value = obj[key];
            str = str.replace(new RegExp(key, 'g'), value);
        }
        return str;
    }

    handleEditorChangeDevis(content) {
      this.setState({contentDevis: content});
    }

    handleEditorChangeAssurance(content) {
        this.setState({contentAssurance: content})
    }

    componentDidMount() {
        this.fillMailContent();
    }

    fillMailContent() {
        const inqueryInfo = JSON.parse(localStorage.getItem('inqueryInfo'));

        console.log('wesh la team')
        console.log(inqueryInfo)
        if (inqueryInfo.InqueryTypeID === "4") {
            const contentAssurance = this.replaceAllContent(this.state.contentAssurance, {
                "__model": inqueryInfo.BoatModel,
                "__maker": inqueryInfo.Maker
            });
            this.setState({contentAssurance});
        } else {
            const contentDevis = this.replaceAllContent(this.state.contentDevis, {
                "__model": inqueryInfo.BoatModel,
                "__type": inqueryInfo.BoatType,
                "__engine": inqueryInfo.Engine,
                "__year": inqueryInfo.BoatYear,
                "__full_address": inqueryInfo.FullAddressBoat,
                "__first_name": inqueryInfo.FirstName,
                "__message": inqueryInfo.Title + '<br/>' + inqueryInfo.Message,
                "__deadlineWeek": inqueryInfo.ResponseTime,
                "ICI": '<a href="www.boaton.fr">ICI</a>'
            });
            this.setState({contentDevis});
        }
    }

    sendMail() {
        const inqueryInfo = JSON.parse(localStorage.getItem('inqueryInfo'));
        let user = JSON.parse(localStorage.getItem('user'));
        console.log('wesh la team 2')
        console.log(inqueryInfo)

        if (inqueryInfo.InqueryTypeID === "1" || inqueryInfo.InqueryTypeID === "2") {
            this.props.idsList.map((id, i) => {
              const data = new FormData();
              data.append("companyid", id);
              data.append("inqueryid", inqueryInfo.InqueryID);
                fetch(API_URL + '/quotation/quotationtokencreate.php', {
                    method: 'POST',
                    headers: {
                        'X-ACCESS-TOKEN': user.AccessToken
                    },
                    body: data
                }).then(response => {
                  return response.json();
                }).then(json => {
                  const data = new FormData();
                  data.append("companyid", id);
                  data.append("inqueryid", inqueryInfo.InqueryID);
                  data.append("message", this.state.contentDevis.replace('{+token+}', json.token));
                  data.append("files", []);
                  if (inqueryInfo.pictures) {
                    console.log(inqueryInfo.pictures.map(picture => {
                      return picture.PictureURL;
                    }))
                    data.append("files", inqueryInfo.pictures.map(picture => {
                      return picture.PictureURL;
                    }) || [])
                  }
                  this.props.postDatas(API_URL + '/inquery/inquerysendemailpro.php', data);
                })
            });

            const data1 = new FormData();
            data1.append("inqueryid", inqueryInfo.InqueryID);
            data1.append("inquerystatus", "2");
            return fetch(API_URL + '/inquerychange.php', {
                method: 'POST',
                headers: {
                    'X-ACCESS-TOKEN': user.AccessToken
                },
                body: data1
            }).then(this.props.close());
        } else if (inqueryInfo.InqueryTypeID === "4") {
            console.log('on passe ici')
            this.props.idsList.map((id, i) => {
                const data = new FormData();
                console.log('for brunello')
                console.log(id)
                data.append("company", id);
                data.append("created", 1);
                data.append("message", this.state.contentAssurance);
                this.props.postDatas(API_URL + '/sendprofessionalemail.php', data);
                const data2 = new FormData();
                data2.append("inqueryid", inqueryInfo.InqueryID);
                data2.append("companyid", id);
                this.props.postDatas(API_URL + '/inqueryreceiver.php', data2);
            });

            var data2 = new FormData();
            data2.append("inqueryid", inqueryInfo.InqueryID);
            data2.append("inquerystatus", "2");
            return fetch(API_URL + '/inquerychange.php', {
                method: 'POST',
                headers: {
                    'X-ACCESS-TOKEN': user.AccessToken
                },
                body: data2
            }).then(this.props.close());

        }
        localStorage.removeItem('inqueryInfo');
    };

    render() {
        const {classes} = this.props;

        const inqueryInfo = JSON.parse(localStorage.getItem("inqueryInfo"));

        return (<div>
            <p>Veuillez vérifier les informations pré-remplies dans le mail puis valider l'envoi en cliquant sur
                ENVOYER</p>
            {inqueryInfo.InqueryTypeID === "4" ?
                <Editor apiKey='roemw219zpkel1zj9oi6f82wz7xym6tycg603tpiqr6dbmic'
                        className={classes.editorStyle} value={this.state.contentAssurance}
                        onEditorChange={this.handleEditorChangeAssurance}/> :
                <Editor apiKey='roemw219zpkel1zj9oi6f82wz7xym6tycg603tpiqr6dbmic'
                        className={classes.editorStyle} value={this.state.contentDevis}
                        onEditorChange={this.handleEditorChangeDevis}/>}
            {this.props.idsList.length === 0 ?
                <p className={classes.message}>Aucunes sélections</p> :
                this.props.idsList.length > 0 ?
                    <p>Professionnel(s) sélectionné(s) : {this.props.idsList.length}</p> : ''}

            <Grid item xs={12} md={12}>
                {this.props.idsList.length === 0 ?
                    <p className={classes.message}>VEUILLEZ SELECTIONNER DES PROFESSIONELS</p> :
                    this.props.idsList.length > 0 ?
                        <Button variant="contained" color="primary" onClick={this.sendMail}>ENVOYER</Button> : ''}
            </Grid>
        </div>
      );
    }
}

function mapStateToProps(state) {
    return ({
        oneQuerie: state.datasReducer.oneQuerie,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error,
    })
}

function mapDispatchToProps(dispatch) {
    return {
        postDatas: bindActionCreators(postDatas, dispatch),
        fetchDatas: bindActionCreators(fetchDatas, dispatch),
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MessageEditor));
