import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Moment from 'react-moment';
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import {connect} from "react-redux";

import Redirect from "react-router-dom/es/Redirect";
import Button from "@material-ui/core/Button";
import { API_URL } from "../../config.js";

const styles = () => ({
    profileInquery: {
        Width: 'max-content',
        border: 'none',
        float: 'center',
        margin: 'auto',
    },
    rounded: {
        display: 'block',
        borderRadius: '50%',
        margin: 'auto',
        marginTop: '10px'
    },
    name: {
        marginTop: '10px',
        fontWeight: '500',
        textAlign: 'center'
    },
    period: {
        textAlign: 'left'
    },
    address: {
        width: '70%'
    },
    divider: {
        marginTop: '10px'
    },
    detail: {
        marginLeft: '10px',
        fontWeight: '500',
        paddingBottom: '10px',
        fontSize: '20px',
    },
    CancelComment: {
        color: "red"
    }
});

class ProfileUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        };
    };

    componentWillMount() {
        const data = new FormData();
        data.append("inqueryid", this.props.id);
        this.props.dispatch(fetchDatasWithName(API_URL + '/thisinquerypanel.php', 'userInfo', 'POST', data));
    };

    setRedirect = () => {
        this.setState({redirect: true})
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={'/info-user/' + this.props.userInfo.UserID}/>
        }
    };

    render() {
        const {classes, error, loading} = this.props;
        if (error || this.props.userInfo === null) {
            return (<div>error in user! {error.message} </div>);
        }

        if (loading || !this.props.userInfo) {
            return <div>Loading ...</div>
        }
        console.log(this.props.userInfo.Birthday)
        console.log(this.props.userInfo.PhoneNumber)

        return (
            <div className={classes.profileInquery}>
                
                <div>
                    {this.props.userInfo && this.props.userInfo && this.props.userInfo.UserPicture ?
                        <img alt="avatar"
                             src={API_URL + '/profilepictures/' + this.props.userInfo.UserPicture}
                             width="100" height="100" className={classes.rounded}/>
                        : <img alt="avatar" src={API_URL + '/profilepictures/defaultuser.png'} width="100"
                               height="100" className={classes.rounded}/>}
                </div>
                <Divider className={classes.divider}/>
                <div>
                    <Typography className={this.props.classes.period}><label>Prénom / Nom : </label> {this.props.userInfo && this.props.userInfo.FirstName} {this.props.userInfo && this.props.userInfo.LasterName} </Typography>
                    <Typography className={this.props.classes.period}><label>Téléphone : </label> {this.props.userInfo && this.props.userInfo.PhoneNumber}</Typography>
                    <Typography className={this.props.classes.period}><label>Date de naissance : </label> {this.props.userInfo && this.props.userInfo.Birthday ? ( <Moment format="DD-MM-YYYY" >{this.props.userInfo.Birthday}</Moment>) : ("")} </Typography>
                    <Typography className={this.props.classes.period}><label>Adresse : </label> {this.props.userInfo && this.props.userInfo.FullAddress}</Typography>
                    <Typography className={this.props.classes.period}><label>Email : </label> {this.props.userInfo && this.props.userInfo.EmailAddress}</Typography>                    

                    {this.renderRedirect()}
                    <Button variant="contained" color="primary" onClick={this.setRedirect}>Aller à l'utilisateur</Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        userInfo: state.datasReducer.userInfo,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error,
    });
}

export default withStyles(styles)(connect(mapStateToProps)(ProfileUser));
